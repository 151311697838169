import { useFeatureValue } from '@growthbook/growthbook-react';
import {
  AccessTime,
  CalendarMonth,
  CloseOutlined,
  Group,
} from '@mui/icons-material';
import {
  Alert,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  useMediaQuery,
} from '@mui/material';
import { format } from 'date-fns';
import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useMemo, useState } from 'react';

import {
  BreakTime,
  PostSetting,
  Schedule,
  ShiftInfo,
  breakOptions,
} from '../../../context';
import CheckboxList from '../ScheduleSection/CheckBoxList';
import {
  checkTimeRange,
  getDifferenceInMinutes,
  isFutureDate,
  isHoliday,
  parseStringsHours,
} from '../util';

import { BreakTimeIcon } from '@/assets/icons';
import Button from '@/components/Button';
import EmptyState from '@/components/EmptyState';
import HolidayInfoAlert from '@/components/HolidayInfoAlert';
import ItemSelect from '@/components/ItemSelect';
import Option from '@/components/Option';
import ShiftTimePicker from '@/components/ShiftTimePicker';
import CustomStack from '@/components/Stack';
import TextInput from '@/components/TextInput';
import { Small, Subheading } from '@/components/Typography';
import { FEATURE_TOGGLE } from '@/constants/featuretoggle';
import {
  DISABLE_BREAK_MINUTES,
  INTERVAL_IN_MINUTES,
} from '@/constants/general';
import Text from '@/elements/Text';
import { useGetWorkerRelationshipsByCustomerQuery } from '@/graphql';
import useAuth from '@/hooks/useAuth';
import breakpoints from '@/styles/breakpoints';
import { IconColor } from '@/styles/colors';
import {
  Address,
  JobTypeEnum,
  Maybe,
  RelationshipKindEnum,
} from '@/types/graphql';

interface ShiftModalProps {
  postSetting: PostSetting;
  schedule?: Schedule;
  onSave: (info: ShiftInfo) => void;
  hideModal: (e: any, reason: string) => void;
  date: Date;
  customerId: string;
  orderType: JobTypeEnum | null;
  holidays?: any[];
  lastShiftInfo?: Schedule;
  skillId?: string;
  address: Maybe<Address>;
}

const ShiftModal = ({
  schedule,
  date,
  onSave,
  hideModal,
  customerId,
  orderType,
  postSetting,
  holidays,
  lastShiftInfo,
  skillId,
  address,
}: ShiftModalProps) => {
  const scheduleInfo = schedule || lastShiftInfo;

  const isLtajob = orderType === JobTypeEnum.LTA;
  const [time, setTime] = useState({
    startTime: scheduleInfo?.startTime || '',
    endTime: scheduleInfo?.endTime || '',
  });
  const [mandatoryBreakTime, setmandatoryBreakTime] = useState(
    breakOptions.find((x) => x.value === scheduleInfo?.mandatoryBreakTime) ||
      breakOptions[0],
  );
  const [quantity, setQuantity] = useState<number | string>(
    scheduleInfo?.quantity || 1,
  );
  const [search, setSearch] = useState('');
  const phoneOnly = useMediaQuery(breakpoints.phoneOnly);
  const { currentAdminIsCustomerAdmin } = useAuth();

  const [disableBreak, setdisableBreak] = useState(false);

  const isFutureJobGig =
    !isLtajob && currentAdminIsCustomerAdmin
      ? true
      : isFutureDate(date, time?.startTime);

  const hireWorkers =
    scheduleInfo?.hiredWorkers && scheduleInfo.hiredWorkers.length > 0
      ? scheduleInfo.hiredWorkers
      : undefined;
  const inviteWorkers =
    scheduleInfo?.selectedWorkers &&
    scheduleInfo.selectedWorkers.length > 0 &&
    isFutureJobGig
      ? scheduleInfo.selectedWorkers
      : undefined;

  const [actionType, setActionType] = useState<'hire' | 'invite' | undefined>(
    isLtajob
      ? 'hire'
      : hireWorkers
        ? 'hire'
        : inviteWorkers
          ? 'invite'
          : undefined,
  );

  const [selectedWorkers, setSelectedWorkers] = useState<any[]>(
    hireWorkers || inviteWorkers || [],
  );

  const { data, loading } = useGetWorkerRelationshipsByCustomerQuery({
    variables: {
      customerId,
      filters: { jurisdiction: address?.state ?? '' },
    },
    fetchPolicy: 'network-only',
  });
  const REL_KIND = isLtajob
    ? RelationshipKindEnum.LTA_WORKERS
    : RelationshipKindEnum.FAVORITED;

  const isHiring = actionType === 'hire';
  const isMandatoryToSelect = !isLtajob && postSetting === 'my_selections';
  const disableSelection =
    Number(quantity) > 0 &&
    selectedWorkers.length === Number(quantity) &&
    isHiring;

  const workersList = useMemo(() => {
    let items = data?.customer?.workerRelationships
      .filter(
        (item) =>
          item.kind === REL_KIND &&
          item.worker.skills.find((s) => s.id === skillId),
      )
      .map((x) => x.worker);

    if (search) {
      items = items?.filter(
        (x) =>
          x.user.firstName.toLowerCase().includes(search.toLowerCase()) ||
          x.user.lastName.toLowerCase().includes(search.toLowerCase()),
      );
    }
    return items || [];
  }, [data?.customer, search]);

  const handleTimeChange = (seltime: Dayjs, event: any, key: string) => {
    const value = seltime ? seltime?.format('HH:mm') : '';
    setTime((val) => ({ ...val, [key]: value }));
  };

  const handleBreakTime = (fieldContext: BreakTime) => {
    setmandatoryBreakTime(fieldContext);
  };
  const holiday = schedule?.isHoliday || isHoliday(holidays || [], date);

  const { validBreakOptions, difference } = useMemo(() => {
    const difference = getDifferenceInMinutes(time.endTime, time.startTime);
    if (difference > DISABLE_BREAK_MINUTES && difference < 480) {
      return { validBreakOptions: breakOptions.slice(0, 3), difference };
    } else {
      return { validBreakOptions: breakOptions, difference };
    }
  }, [time.startTime, time.endTime]);

  useEffect(() => {
    if (!difference) return;
    if (difference <= DISABLE_BREAK_MINUTES) {
      setmandatoryBreakTime(breakOptions[0]);
      setdisableBreak(true);
    } else {
      if (difference < 480 && mandatoryBreakTime.value > 30) {
        setmandatoryBreakTime(breakOptions.find((b) => b.value === 30)!);
      }
      setdisableBreak(false);
    }
  }, [difference]);

  useEffect(() => {
    const num = Number(quantity);
    if (num > 0 && isHiring && selectedWorkers.length > num) {
      setSelectedWorkers(
        selectedWorkers.slice(0, num - selectedWorkers.length),
      );
    }
    if (selectedWorkers.length > 0 && !isFutureJobGig) {
      setActionType('hire');
    }
  }, [quantity, selectedWorkers, isHiring]);

  const errorMsg = useMemo(() => {
    if (
      time.startTime &&
      time.endTime &&
      checkTimeRange(time.endTime, time.startTime, INTERVAL_IN_MINUTES)
    ) {
      return { msg: 'Job duration should be at least 4 hours.', disable: true };
    }
    if (
      time.startTime &&
      currentAdminIsCustomerAdmin &&
      date <= new Date() &&
      parseStringsHours(time.startTime) <= new Date().getTime()
    ) {
      return { msg: 'Shift time cannot be in the past', disable: true };
    }

    if (isMandatoryToSelect && selectedWorkers.length === 0) {
      return {
        msg: 'You have to invite or hire minimum one worker',
        disable: true,
      };
    }
    if (!isLtajob && selectedWorkers.length > 0 && !actionType) {
      return {
        msg: 'You need to select the option to hire or invite selected workers',
        disable: true,
      };
    }
  }, [
    time,
    quantity,
    isMandatoryToSelect,
    selectedWorkers,
    disableSelection,
    isLtajob,
    actionType,
  ]);

  const disableAdd = !time.startTime || !time.endTime || errorMsg?.disable;

  const handleSave = () => {
    const info = {
      date,
      startTime: time.startTime,
      endTime: time.endTime,
      mandatoryBreakTime: mandatoryBreakTime.value,
      quantity: Number(quantity),
      groupId: schedule?.groupId,
      hiredWorkers:
        selectedWorkers.length > 0 && isHiring ? selectedWorkers : [],
      invitedWorkers:
        selectedWorkers.length > 0 && actionType === 'invite'
          ? selectedWorkers
          : [],
    };
    onSave(info);
  };

  return (
    <Dialog
      PaperProps={{
        style: {
          width: '480px',
          marginTop: 0,
          margin: phoneOnly ? 0 : undefined,
          borderRadius: phoneOnly ? '12px' : '',
        },
      }}
      maxWidth="md"
      open={true}
      sx={{
        '& .MuiDialog-container': phoneOnly
          ? {
              alignItems: 'end',
              marginBottom: '2px',
            }
          : {},
      }}
      onClose={hideModal}
    >
      <DialogTitle style={{ backgroundColor: '#EEFFEC', padding: '12px 20px' }}>
        <b>{`${schedule ? 'Edit' : 'Create'} ${
          isMandatoryToSelect ? 'Private' : ''
        } ${orderType === JobTypeEnum.GIG ? 'Gig' : orderType} Shift`}</b>
        <IconButton
          aria-label="close"
          sx={{
            float: 'right',
            padding: '2px',
            color: IconColor,
          }}
          onClick={(e) => hideModal(e, '')}
        >
          <CloseOutlined />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Grid
          container
          columnSpacing={3}
          rowSpacing={phoneOnly ? 1.5 : 2.5}
          sx={{ mt: 0 }}
        >
          <Grid item sm={3} xs={3.5}>
            <Stack direction={'row'}>
              <CalendarMonth
                fontSize="medium"
                htmlColor={IconColor}
                style={{ marginRight: '10px', top: 1 }}
              />
              <Subheading>Date</Subheading>
            </Stack>
          </Grid>
          <Grid item sm={9} xs={8}>
            {date && format(date, 'MMMM dd, yyyy')}
          </Grid>
          <Grid item sm={3} xs={12}>
            <Stack direction={'row'} mt={1}>
              <AccessTime
                fontSize="medium"
                htmlColor={IconColor}
                style={{ marginRight: '10px', top: 1 }}
              />
              <Subheading>Time</Subheading>
            </Stack>
          </Grid>
          <Grid item sm={4.5} xs={12}>
            <ShiftTimePicker
              defaultValue={dayjs(scheduleInfo?.startTime || '', 'HH:mm')}
              id="start-time"
              label="Start time"
              minutesStep={15}
              name="startTime"
              onChange={handleTimeChange}
            />
          </Grid>
          <Grid item sm={4.5} xs={12}>
            <ShiftTimePicker
              defaultValue={dayjs(scheduleInfo?.endTime || '', 'HH:mm')}
              id="end-time"
              label="End time"
              minutesStep={15}
              name="endTime"
              onChange={handleTimeChange}
            />
          </Grid>
          <Grid item sm={3} xs={12}>
            <Stack direction={'row'} mt={1}>
              <BreakTimeIcon
                fontSize="medium"
                style={{ marginRight: '10px', top: 1 }}
              />
              <Subheading>Break</Subheading>
            </Stack>
          </Grid>
          <Grid item sm={9} xs={12}>
            <ItemSelect
              required
              disabled={disableBreak}
              fixedSelectHeight={phoneOnly ? 'auto' : 'lg'}
              id="mandatoryBreakTime"
              itemToKey={(item) => item.value}
              itemToString={(item) => (item ? item.label : '')}
              items={validBreakOptions}
              placeholder="Select Break Time"
              selectedItem={mandatoryBreakTime}
              onSelectedItemChange={({ selectedItem }) => {
                handleBreakTime(selectedItem || breakOptions[0]);
              }}
            />
            {disableBreak && (
              <Text>
                <i>
                  Shifts shorter than 6 hours may not have mandatory break
                  times.
                </i>
              </Text>
            )}
          </Grid>
          <Grid item sm={3} xs={12}>
            <Stack direction={'row'} mt={1}>
              <Group
                fontSize="medium"
                htmlColor={IconColor}
                style={{ marginRight: '8px', top: 1 }}
              />
              <Subheading>Quantity</Subheading>
            </Stack>
          </Grid>
          <Grid item sm={9} xs={12}>
            <TextInput
              min="1"
              placeholder="Workers quantity"
              type="number"
              value={quantity}
              width="100%"
              onBlur={(e) => {
                if (Number(e.target.value) < 1) {
                  setQuantity(1);
                }
              }}
              onChange={(e) => {
                setQuantity(e.target.value);
              }}
            />
          </Grid>

          {!isLtajob && (
            <>
              <Grid item sm={5.3} xs={12}>
                <Text as="span" size={'md'} weight="bold">
                  Do you want to:
                  <Small>
                    {isMandatoryToSelect ? '(Mandatory)' : ' (optional)'}
                  </Small>
                </Text>
              </Grid>
              <Grid
                item
                marginRight={'-8px !important'}
                paddingLeft={phoneOnly ? '' : '8px !important'}
                sm={6.8}
                xs={12}
              >
                <Stack direction={phoneOnly ? 'column' : 'row'} spacing={0.5}>
                  <Option
                    checked={actionType === 'hire'}
                    id="hire"
                    label={'Hire Workers'}
                    type="radio"
                    onChange={() => {
                      setActionType('hire');
                    }}
                  />
                  {isFutureJobGig && (
                    <Option
                      checked={actionType === 'invite'}
                      id="invite"
                      label={'Invite Workers'}
                      type="radio"
                      onChange={() => {
                        setActionType('invite');
                      }}
                    />
                  )}
                </Stack>
              </Grid>
            </>
          )}
          {holiday && (
            <Grid item paddingTop={'16px !important'} xs={12}>
              <HolidayInfoAlert
                rate={holiday.rate}
                style={{ marginBottom: '0px' }}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <TextInput
              minWidth="sm"
              placeholder="Search workers name here"
              value={search}
              onChange={(ev) => setSearch(ev.currentTarget.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Text as="span" size={'md'} weight="bold">
              {isLtajob ? `Hire LTA Workers` : 'Favourite Workers'}{' '}
              <Small>
                {isMandatoryToSelect ? '(Mandatory)' : '(optional)'}
              </Small>
            </Text>
          </Grid>
          {workersList.length > 0 && !loading ? (
            <Grid
              item
              paddingLeft={'12px !important'}
              paddingTop={'10px !important'}
              style={{ minHeight: '120px' }}
              xs={12}
            >
              <CheckboxList
                data={workersList}
                disableAdd={disableSelection}
                selectedData={selectedWorkers}
                setSelectedOptions={setSelectedWorkers}
                style={{
                  overflowY: 'scroll',
                  overflowX: 'hidden',
                  maxHeight: '210px',
                }}
              />
            </Grid>
          ) : (
            <CustomStack align="center" justify="center">
              <EmptyState
                title={`No ${isLtajob ? 'LTA' : 'Favorite'} GravyWorkers.`}
              />
            </CustomStack>
          )}
          {errorMsg && (
            <Grid item paddingTop={'8px !important'} xs={12}>
              <Alert icon={false} severity="error">
                {errorMsg.msg}
              </Alert>
            </Grid>
          )}
          <Grid item xs={12}>
            <Button
              a11yLabel="Add shift"
              css={{ width: '100%' }}
              disabled={disableAdd}
              label={`${schedule ? 'Save' : 'Add'}`}
              onClick={handleSave}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ShiftModal;
