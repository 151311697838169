import {
  CancelOutlined,
  ChatOutlined,
  CheckCircleOutline,
} from '@mui/icons-material';
import StarIcon from '@mui/icons-material/Star';
import { Box, MenuItem, Stack as MuiStack, Stack } from '@mui/material';
import { ReactNode, useState } from 'react';
import { useModal } from 'react-modal-hook';

import { TimesheetItem } from '../TimesheetList';
import { TimeSheetStatus, calculateNetTime, getTimeSheetStatus } from '../util';

import ProfileModalRevamp from './ProfileModal/ProfileModalRevamp';

import {
  CancelNoShowIcon,
  GravyTrained,
  MapMarkerIcon,
  InfoItalicIcon,
  AddTipIcon,
  RemoveIcon,
  AccountDollarIcon,
  NoshowIcon,
  HeartFillIcon,
  HeartIcon,
} from '@/assets/icons';
import ClientApproveIcon from '@/assets/icons/ClientApprove';
import ClientUnRejectIcon from '@/assets/icons/ClientUnReject';
import Avatar from '@/components/Avatar';
import StyledChip from '@/components/Chip';
import TextStack from '@/components/TextStack';
import { Body } from '@/components/Typography';
import Text from '@/elements/Text';
import styled from '@/styles';
import {
  GetJobQuery,
  RelationshipKindEnum,
  TimesheetStatusEnum,
} from '@/types/graphql';
import { centsToCurrency } from '@/util/number';
import { containsGravyCertification } from '@/util/worker';
import IconicButton from '@/components/IconicButton';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import PermissionComponent from '@/routes/PermissionComponent';

const IconColor = '#1C1C1C';
interface WorkerPayRateProps {
  job: GetJobQuery['job'];
  workerId: any;
  timesheet?: TimesheetItem;
  clientAdmin: boolean;
  addSeperator?: boolean;
}

const CardCriteria = styled('span', {
  fontSize: '12px',
  fontWeight: '600',
  color: '#262626',
});

const ApprovalAlert = styled('p', {
  fontSize: '12px',
  fontWeight: '400',
  color: '#fff',
  background: '#ECA300',
  padding: '3px 8px',
  borderRadius: '8px',
  textAlign: 'center',
});

export const getShiftTime = (timesheet) => {
  const {
    netApprovedTime,
    reportedCheckinAt,
    reportedCheckoutAt,
    reportedBreakMinutes,
    checkinAt,
    checkoutAt,
  } = timesheet;
  let approvedTime = netApprovedTime;
  if (!approvedTime && reportedCheckinAt && reportedCheckoutAt) {
    approvedTime = calculateNetTime(
      reportedCheckinAt,
      reportedCheckoutAt,
      reportedBreakMinutes,
    );
  } else if (!approvedTime && checkinAt && checkoutAt) {
    approvedTime = calculateNetTime(
      checkinAt,
      checkoutAt,
      reportedBreakMinutes,
    );
  }
  return approvedTime / 60;
};

export const RenderWorkerInfo = ({
  workerInfo,
  isClientAdmin,
  StatusChip,
  job,
  timeSheet,
  relationshipStatus,
  createWorkerRelationship,
  deleteWorkerRelationship,
}: {
  workerInfo: any;
  isClientAdmin: boolean;
  StatusChip?: ReactNode;
  handleAddComment?: any;
  timeSheet?: any;
  job?: GetJobQuery['job'];
  relationshipStatus: RelationshipKindEnum | null;
  createWorkerRelationship: (
    reason: string,
    comments: string,
    kind: RelationshipKindEnum,
  ) => void;
  deleteWorkerRelationship: (kind: RelationshipKindEnum) => void;
}) => {
  const [showProfileRevampModal, hideProfileRevampModal] = useModal(
    () => (
      <ProfileModalRevamp
        ProfileInfo={workerInfo}
        hideModal={hideProfileRevampModal}
        isClientAdmin={isClientAdmin}
        relationshipStatus={relationshipStatus}
        createWorkerRelationship={createWorkerRelationship}
        deleteWorkerRelationship={deleteWorkerRelationship}
      />
    ),
    [workerInfo, relationshipStatus],
  );
  const [highlight, setHighlight] = useState(false);
  const isCertified = containsGravyCertification(workerInfo.activeCertificates);
  const tsStatus = getTimeSheetStatus(timeSheet);

  const handleClick = () => {
    showProfileRevampModal();
  };
  const handleMouseEnter = () => setHighlight(true);
  const handleMouseLeave = () => setHighlight(false);

  return (
    <MuiStack
      direction={'row'}
      spacing={1.5}
      sx={{ ':hover': { cursor: 'pointer' } }}
    >
      <MuiStack
        alignItems={'center'}
        id="worker-info-div"
        justifyContent={'center'}
        spacing={1.7}
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Box sx={{ position: 'relative' }}>
          <Avatar
            border={isCertified}
            rounded={true}
            size="lg2"
            src={workerInfo.avatarUrl}
          />
          {isCertified && (
            <GravyTrained
              id="gravy-badge"
              sx={{
                position: 'absolute',
                right: 0,
                bottom: 0,
                width: '28px',
                height: '28px',
              }}
            />
          )}
        </Box>
      </MuiStack>
      <MuiStack spacing={1.2}>
        <div style={{ minHeight: '78px' }}>
          <WorkerDesc
            highlight={highlight}
            isClientAdmin={isClientAdmin}
            job={job}
            showRating={true}
            timesheet={timeSheet}
            workerInfo={workerInfo}
            relationshipStatus={relationshipStatus}
            createWorkerRelationship={createWorkerRelationship}
            deleteWorkerRelationship={deleteWorkerRelationship}
            onClick={handleClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          />
          <Stack alignItems={'center'} flexDirection={'row'} gap={'10px'}>
            <div className="worker-status-chip">{StatusChip}</div>
            {tsStatus === TimeSheetStatus.PENDING && (
              <ApprovalAlert>Needs Approval</ApprovalAlert>
            )}
          </Stack>
        </div>
      </MuiStack>
    </MuiStack>
  );
};

export const WorkerPayRate = ({
  job,
  workerId,
  timesheet,
  clientAdmin,
  addSeperator,
}: WorkerPayRateProps) => {
  const jobWorker = job?.jobWorkers?.find((x) => x.worker.id === workerId);
  let amount: string = '';
  if (clientAdmin) {
    if (timesheet && timesheet.tipAmount) {
      amount = `${timesheet.tipAmount} Tip`;
    }
  } else if (jobWorker) {
    amount = centsToCurrency(jobWorker.payRate) + '/ hr';
  }

  if (amount.length) {
    return (
      <>
        {addSeperator && (
          <div style={{ marginLeft: 15, marginRight: 10 }}>|</div>
        )}
        <Stack alignItems={'center'} flexDirection={'row'} gap={'2px'}>
          <AccountDollarIcon
            sx={{
              width: '16px',
              height: '16px',
            }}
          />
          <CardCriteria>{amount}</CardCriteria>
        </Stack>
      </>
    );
  }
  return null;
};

export const WorkerDesc = ({
  workerInfo,
  isClientAdmin,
  showRating,
  highlight = false,
  job,
  timesheet,
  relationshipStatus,
  createWorkerRelationship,
  deleteWorkerRelationship,
  onClick,
  onMouseEnter,
  onMouseLeave,
}: {
  workerInfo: any;
  isClientAdmin: boolean;
  showRating?: boolean;
  highlight?: boolean;
  job?: GetJobQuery['job'];
  timesheet?: TimesheetItem;
  relationshipStatus: RelationshipKindEnum | null;
  createWorkerRelationship: (
    reason: string,
    comments: string,
    kind: RelationshipKindEnum,
  ) => void;
  deleteWorkerRelationship: (kind: RelationshipKindEnum) => void;
  onClick?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}) => {
  const { firstName, middleName, lastName } = workerInfo.user;
  return (
    <Stack
      flexDirection={'row'}
      style={{ alignItems: 'flex-start', columnGap: '5px' }}
    >
      <div
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <TextStack>
          <Body
            color={highlight ? 'themeDefault' : undefined}
            css={{
              lineHeight: '1 !important',
              textDecoration: highlight ? 'underline' : 'none',
              display: 'flex',
              flexWrap: 'wrap',
              paddingTop: '7px',
            }}
            weight="medium"
          >
            {firstName} {middleName} {lastName}
          </Body>
          {showRating && (
            <MuiStack
              alignItems={'center'}
              direction={'row'}
              marginY={1}
              spacing={0.6}
            >
              <StarIcon
                id="star-icon"
                sx={{ color: '#faaf00', width: '20px', height: '20px' }}
              />
              <Text size={'md'} weight={'semibold'}>
                {workerInfo.averageRating
                  ? workerInfo.averageRating.toFixed(1)
                  : '0.0'}
              </Text>
              <Text color={'lighter'}>{`(${workerInfo.jobsRatingCount})`}</Text>
              <WorkerPayRate
                addSeperator={true}
                clientAdmin={isClientAdmin}
                job={job}
                timesheet={timesheet}
                workerId={workerInfo.id}
              />
            </MuiStack>
          )}
        </TextStack>
      </div>
      <PermissionComponent id="toggle-favorite-btn" showAsReadOnly>
        <IconicButton
          a11yLabel="Toggle Favorite"
          appearance="clear"
          transparentOnHover={true}
          customIcon={
            relationshipStatus === RelationshipKindEnum.FAVORITED ? (
              <HeartFillIcon
                style={{
                  cursor: 'pointer',
                }}
              />
            ) : (
              <HeartIcon
                style={{
                  cursor: 'pointer',
                }}
              />
            )
          }
          data-testid="toggle-favorite"
          icon={faCircle}
          id="toggle-favorite-btn"
          size="sm"
          type="button"
          onClick={() => {
            if (relationshipStatus === RelationshipKindEnum.FAVORITED) {
              deleteWorkerRelationship(RelationshipKindEnum.FAVORITED);
            } else {
              createWorkerRelationship('', '', RelationshipKindEnum.FAVORITED);
            }
          }}
        />
      </PermissionComponent>
    </Stack>
  );
};

export const renderTimeSheetStatusChip = (status?: TimeSheetStatus) => {
  if (!status) return '-';
  switch (status) {
    case TimeSheetStatus.INCOMPLETE:
      return (
        <Text color={'light'} size={'sm'}>
          {'Incomplete'}
        </Text>
      );
    case TimeSheetStatus.PENDING:
      return <StyledChip label={'Pending'} size="small" />;
    case TimeSheetStatus.APPROVED:
      return <StyledChip color="success" label={'Approved'} size="small" />;
    case TimeSheetStatus.REJECTED:
      return <StyledChip color="error" label={'Rejected'} size="small" />;
    default:
      break;
  }
};

export const MenuItemsObject = {
  addComment: (handleAddComment, showComment) =>
    showComment ? (
      <MenuItem id="btn-add-comment" onClick={handleAddComment}>
        <ChatOutlined htmlColor={IconColor} style={{ marginRight: '10px' }} />
        Add Comment
      </MenuItem>
    ) : undefined,
  noShow: (handleToggleShowed) => (
    <MenuItem id="btn-noshow" onClick={handleToggleShowed}>
      <CancelNoShowIcon style={{ marginRight: '10px' }} />
      Mark as No Show
    </MenuItem>
  ),
  cancelNoshow: (handleToggleShowed) => (
    <MenuItem id="btn-cancel-noshow" onClick={handleToggleShowed}>
      <CancelNoShowIcon style={{ marginRight: '10px' }} />
      Cancel No show
    </MenuItem>
  ),
  removeWorker: (handleRemoveTimesheet) => (
    <MenuItem id="btn-remove" onClick={handleRemoveTimesheet}>
      <RemoveIcon htmlColor={IconColor} style={{ marginRight: '10px' }} />
      Remove
    </MenuItem>
  ),
  showMap: (handleShowMap) => (
    <MenuItem id="btn-showmap" onClick={handleShowMap}>
      <MapMarkerIcon htmlColor={IconColor} style={{ marginRight: '10px' }} />
      View Map
    </MenuItem>
  ),
  addTip: (handleAddTip) => (
    <MenuItem id="btn-addTip" onClick={handleAddTip}>
      <AddTipIcon htmlColor={IconColor} style={{ marginRight: '10px' }} />
      Tip Worker
    </MenuItem>
  ),
  enable: (enableActionCallback) => (
    <MenuItem id="btn-enable" onClick={enableActionCallback}>
      <ClientApproveIcon
        style={{
          marginLeft: 4,
          fontSize: '18px',
          marginRight: 10,
        }}
      />
      Enable
    </MenuItem>
  ),
  disable: (disableActionCallback) => (
    <MenuItem id="btn-disable" onClick={disableActionCallback}>
      <NoshowIcon
        style={{
          marginLeft: 4,
          fontSize: '17px',
          marginRight: 10,
        }}
      />
      Disable
    </MenuItem>
  ),
  approve: (handleApproveTimesheet) => (
    <MenuItem id="btn-approve" onClick={handleApproveTimesheet}>
      <CheckCircleOutline
        fontSize="small"
        htmlColor={IconColor}
        style={{ marginRight: '10px' }}
      />
      Approve
    </MenuItem>
  ),
  reject: (handleRejectTimesheet) => (
    <MenuItem id="btn-reject" onClick={handleRejectTimesheet}>
      <CancelOutlined
        fontSize="small"
        htmlColor={IconColor}
        style={{ marginRight: '10px' }}
      />
      Reject
    </MenuItem>
  ),
  unReject: (handleUnrejectTimesheet) => (
    <MenuItem id="btn-unreject" onClick={handleUnrejectTimesheet}>
      <ClientUnRejectIcon
        fontSize="small"
        htmlColor={IconColor}
        style={{ marginRight: '10px' }}
      />
      Unreject
    </MenuItem>
  ),
};

export const RemovedWorkerChip = (
  <StyledChip
    icon={
      <InfoItalicIcon
        style={{
          color: '#5C5C5C',
        }}
      />
    }
    label="Removed"
    size="small"
    sx={{
      fontSize: '0.8rem',
      bgcolor: '#DDE1E0',
      color: '#5C5C5C',
      paddingLeft: '4px',
      borderRadius: '8px',
    }}
  />
);

export const NoShowChip = (
  <StyledChip
    icon={
      <InfoItalicIcon
        style={{
          color: '#DC1515',
        }}
      />
    }
    label="No Show"
    size="small"
    sx={{
      fontSize: '0.8rem',
      color: '#DC1515',
      bgcolor: '#FFE1E1',
      paddingLeft: '4px',
      borderRadius: '8px',
    }}
  />
);
export const HiredChip = (
  <StyledChip
    label="Hired"
    size="small"
    sx={{
      fontSize: '0.8rem',
      bgcolor: '#E0EBFF',
      color: '#3A5FA0',
      borderRadius: '8px',
    }}
  />
);

export const ConfirmedChip = (
  <StyledChip
    label="Confirmed"
    size="small"
    sx={{
      fontSize: '0.75rem',
      bgcolor: '#45a735',
      color: '#FFF',
    }}
  />
);

export const TimesheetStatusChip = ({ status }) => {
  let bgColor = '#000';
  let label = 'Approved';
  if (status === TimesheetStatusEnum.REJECTED) {
    label = 'Rejected';
    bgColor = '#DC1515';
  } else if (status === TimesheetStatusEnum.PENDING) {
    label = 'Needs Approval';
    bgColor = '#45a735';
  } else if (status !== TimesheetStatusEnum.APPROVED) {
    return <></>;
  }

  return (
    <StyledChip
      label={label}
      size="small"
      sx={{
        fontSize: '0.75rem',
        bgcolor: bgColor,
        color: '#FFF',
      }}
    />
  );
};

export const InvitedChip = (
  <StyledChip
    label="Invited"
    size="small"
    sx={{
      fontSize: '0.8rem',
      borderRadius: '8px',
      color: '#106F00',
      bgcolor: '#DDEFD6',
    }}
  />
);

export const DroppedChip = (
  <StyledChip
    icon={
      <InfoItalicIcon
        style={{
          color: '#ECA300',
        }}
      />
    }
    label="Dropped"
    size="small"
    sx={{
      fontSize: '0.8rem',
      bgcolor: '#FFF0C8',
      color: '#ECA300',
      paddingLeft: '4px',
      borderRadius: '8px',
    }}
  />
);

export const DeclinedChip = (
  <StyledChip
    icon={
      <InfoItalicIcon
        style={{
          color: '#7456ED',
        }}
      />
    }
    label="Declined"
    size="small"
    sx={{
      fontSize: '0.8rem',
      color: '#7456ED',
      bgcolor: '#F4ECFF',
      paddingLeft: '4px',
      borderRadius: '8px',
    }}
  />
);
