import { WorkerTabs } from '@/util/axios/types';

export enum GAEvent {
  Signup = 'Signup',
  FirstLogin = 'FirstLogin',
  BillingSetup = 'BillingSetup',
  SetPaymentMethod = 'SetPaymentMethod',
  Login = 'Login',
  TimesheetSave = 'TimesheetSave',
  TimesheetApprove = 'TimesheetApprove',
  TimesheetReject = 'TimesheetReject',
  TimesheetUnReject = 'TimesheetUnReject',
  TimesheetUnapprove = 'TimesheetUnapprove',
  StepOneGig = 'StepOneGig',
  StepTwoGig = 'StepTwoGig',
  StepThreeGig = 'StepThreeGig',
  StepFourMarkupGig = 'StepFourMarkupGig',
  StepFourStaticGig = 'StepFourStaticGig',
  StepFiveMarkupGig = 'StepFiveMarkupGig',
  StepFiveStaticGig = 'StepFiveStaticGig',
  StepSixMarkupGig = 'StepSixMarkupGig',
  StepOneLta = 'StepOneLta',
  StepTwoLta = 'StepTwoLta',
  StepThreeLta = 'StepThreeLta',
  StepFourMarkupLta = 'StepFourMarkupLta',
  StepFourStaticLta = 'StepFourStaticLta',
  StepFiveMarkupLta = 'StepFiveMarkupLta',
  StepFiveStaticLta = 'StepFiveStaticLta',
  StepSixMarkupLta = 'StepSixMarkupLta',
  TimeStepOneGig = 'TimeStepOneGig',
  TimeStepTwoGig = 'TimeStepTwoGig',
  TimeStepThreeGig = 'TimeStepThreeGig',
  TimeStepFourGig = 'TimeStepFourGig',
  TimeStepFiveGig = 'TimeStepFiveGig',
  TimeStepOneLta = 'TimeStepOneLta',
  TimeStepTwoLta = 'TimeStepTwoLta',
  TimeStepThreeLta = 'TimeStepThreeLta',
  TimeStepFourLta = 'TimeStepFourLta',
  TimeStepFiveLta = 'TimeStepFiveLta',
  WorkerListSearch = 'WorkerListSearch',
  OrderCreateHireWorker = 'OrderCreateHireWorker',
  OrderCreateInviteWorker = 'OrderCreateInviteWorker',
  EditJobRates = 'EditJobRates',
  JobHireWorker = 'JobHireWorker',
  JobInviteWorker = 'JobInviteWorker',
  DisableBillingAccount = 'DisableBillingAccount',
  ReEnableBillingAccount = 'ReEnableBillingAccount',
  ViewJobDetails = 'ViewJobDetails',
  TimesheetComment = 'TimesheetComment',
  TimesheetRating = 'TimesheetRating',
  TimesheetViewMap = 'TimesheetViewMap',
  ClientAppUpdate = 'ClientAppUpdate',
  ClientAppForceUpdated = 'ClientAppForceUpdated',
  WorkerFavorite = 'WorkerFavorite',
  WorkerUnfavorite = 'WorkerUnfavorite',
  WorkerBlocked = 'WorkerBlocked',
  WorkerUnblocked = 'WorkerUnblocked',
  TenantLogin = 'TenantLogin',
  CreateTenantAdmin = 'CreateTenantAdmin',
  CreateHelpdeskAdmin = 'CreateHelpdeskAdmin',
  CreateClientAdmin = 'CreateClientAdmin',
}

export const eventMap = {
  [GAEvent.Signup]: {
    category: 'User',
    action: 'Customer Signup',
    label: 'Customer Signup',
  },
  [GAEvent.FirstLogin]: {
    category: 'User',
    action: 'First Login',
    label: 'First Login',
  },
  [GAEvent.BillingSetup]: {
    category: 'User',
    action: 'Setup Billing',
    label: 'Setup Billing',
  },
  [GAEvent.SetPaymentMethod]: {
    category: 'User',
    action: 'Setup Payment Method',
    label: 'Setup Payment Method',
  },
  [GAEvent.Login]: {
    category: 'User',
    action: 'Customer Login',
    label: 'Customer Login',
  },
  [GAEvent.TenantLogin]: {
    category: 'User',
    action: 'Tenant Login',
    label: 'Tenant Login',
  },
  [GAEvent.TimesheetApprove]: {
    category: 'Timesheet',
    action: 'Approve Timesheet',
    label: 'Approve Timesheet',
  },
  [GAEvent.TimesheetSave]: {
    category: 'Timesheet',
    action: 'Save Timesheet',
    label: 'Save Timesheet',
  },
  [GAEvent.TimesheetReject]: {
    category: 'Timesheet',
    action: 'Reject Timesheet',
    label: 'Reject Timesheet',
  },
  [GAEvent.TimesheetUnapprove]: {
    category: 'Timesheet',
    action: 'Unapprove Timesheet',
    label: 'Unapprove Timesheet',
  },
  [GAEvent.TimesheetUnReject]: {
    category: 'Timesheet',
    action: 'Unreject Timesheet',
    label: 'Unreject Timesheet',
  },
  [GAEvent.StepOneGig]: {
    category: 'Order Create',
    action: 'Step 1 Gig',
    label: 'Skill Selection',
  },
  [GAEvent.StepTwoGig]: {
    category: 'Order Create',
    action: 'Step 2 Gig',
    label: 'Schedule',
  },
  [GAEvent.StepThreeGig]: {
    category: 'Order Create',
    action: 'Step 3 Gig',
    label: 'Staffing',
  },
  [GAEvent.StepFourMarkupGig]: {
    category: 'Order Create',
    action: 'Step 4 Gig(Markup)',
    label: 'Instructions',
  },
  [GAEvent.StepFourStaticGig]: {
    category: 'Order Create',
    action: 'Step 4 Gig(Static)',
    label: 'Instructions',
  },
  [GAEvent.StepFiveMarkupGig]: {
    category: 'Order Create',
    action: 'Step 5 Gig(Markup)',
    label: 'Pay Rate',
  },
  [GAEvent.StepFiveStaticGig]: {
    category: 'Order Create',
    action: 'Step 5 Gig(Static)',
    label: 'Submission',
  },
  [GAEvent.StepSixMarkupGig]: {
    category: 'Order Create',
    action: 'Step 6 Gig(Markup)',
    label: 'Submission',
  },
  [GAEvent.StepOneLta]: {
    category: 'Order Create',
    action: 'Step 1 LTA',
    label: 'Skill Selection',
  },
  [GAEvent.StepTwoLta]: {
    category: 'Order Create',
    action: 'Step 2 LTA',
    label: 'Schedule',
  },
  [GAEvent.StepThreeLta]: {
    category: 'Order Create',
    action: 'Step 3 LTA',
    label: 'Staffing',
  },
  [GAEvent.StepFourStaticLta]: {
    category: 'Order Create',
    action: 'Step 4 LTA(Static)',
    label: 'Instruction',
  },
  [GAEvent.StepFourMarkupLta]: {
    category: 'Order Create',
    action: 'Step 4 LTA(Markup)',
    label: 'Instruction',
  },
  [GAEvent.StepFiveMarkupLta]: {
    category: 'Order Create',
    action: 'Step 5 LTA(Markup)',
    label: 'Pay Rate',
  },
  [GAEvent.StepFiveStaticLta]: {
    category: 'Order Create',
    action: 'Step 5 LTA(Static)',
    label: 'Submission',
  },
  [GAEvent.StepSixMarkupLta]: {
    category: 'Order Create',
    action: 'Step 6 LTA(Markup)',
    label: 'Submission',
  },
  [WorkerTabs.All]: {
    category: 'Worker Tabs',
    action: 'All Tab',
    label: 'All Tab',
  },
  [WorkerTabs.Favorites]: {
    category: 'Worker Tabs',
    action: 'Favorites Tab',
    label: 'Favorites Tab',
  },
  [WorkerTabs.GravyTrained]: {
    category: 'Worker Tabs',
    action: 'Gravy Trained Tab',
    label: 'Gravy Trained Tab',
  },
  [WorkerTabs.HighlyRated]: {
    category: 'Worker Tabs',
    action: 'Highly Rated Tab',
    label: 'Highly Rated Tab',
  },
  [WorkerTabs.PastWorkers]: {
    category: 'Worker Tabs',
    action: 'Past Worker Tab',
    label: 'Past Worker Tab',
  },
  [GAEvent.WorkerListSearch]: {
    category: 'Worker List',
    action: 'Worker List Search',
    label: 'Worker List Search',
  },
  [GAEvent.OrderCreateHireWorker]: {
    category: 'Order Create',
    action: 'Order Create Hire Worker',
    label: 'Order Create Hire Worker',
  },
  [GAEvent.OrderCreateInviteWorker]: {
    category: 'Order Create',
    action: 'Order Create Invite Worker',
    label: 'Order Create Invite Worker',
  },
  [GAEvent.EditJobRates]: {
    category: 'Edit Job',
    action: 'Edit Job Rates',
    label: 'Edit Job Rates',
  },
  [GAEvent.JobHireWorker]: {
    category: 'Job Update',
    action: 'Hire Worker',
    label: 'Job Hire Worker',
  },
  [GAEvent.JobInviteWorker]: {
    category: 'Job Update',
    action: 'Invite Worker',
    label: 'Invite Worker',
  },
  [GAEvent.DisableBillingAccount]: {
    category: 'Billing Account',
    action: 'Disable Billing Account',
    label: 'Disable Billing Account',
  },
  [GAEvent.ReEnableBillingAccount]: {
    category: 'Billing Account',
    action: 'Re-enable Billing Account',
    label: 'Re-enable Billing Account',
  },
  [GAEvent.ViewJobDetails]: {
    category: 'Job Details',
    action: 'View Job Details',
    label: 'View Job Details',
  },
  [GAEvent.TimesheetComment]: {
    category: 'Timesheet',
    action: 'Timesheet Comment',
    label: 'Timesheet Comment',
  },
  [GAEvent.TimesheetRating]: {
    category: 'Timesheet',
    action: 'Timesheet Rating',
    label: 'Timesheet Rating',
  },
  [GAEvent.TimesheetViewMap]: {
    category: 'Timesheet',
    action: 'Timesheet View Map',
    label: 'Timesheet View Map',
  },
  [GAEvent.WorkerFavorite]: {
    category: 'Worker List',
    action: 'Worker Marked As Favorite',
    label: 'Worker Marked As Favorite',
  },
  [GAEvent.WorkerUnfavorite]: {
    category: 'Worker List',
    action: 'Worker Unmarked As Favorite',
    label: 'Worker Unmarked As Favorite',
  },
  [GAEvent.WorkerBlocked]: {
    category: 'Worker List',
    action: 'Worker Marked As Blocked',
    label: 'Worker Marked As Blocked',
  },
  [GAEvent.WorkerUnblocked]: {
    category: 'Worker List',
    action: 'Worker Unmarked As Block',
    label: 'Worker Unmarked As Block',
  },
  [GAEvent.CreateTenantAdmin]: {
    category: 'Tenant Admin Created',
    action: 'Tenant Admin has been created',
    label: 'Tenant Admin has been created',
  },
  [GAEvent.CreateHelpdeskAdmin]: {
    category: 'Helpdesk Admin Created',
    action: 'Helpdesk Admin has been created',
    label: 'Helpdesk Admin has been created',
  },
  [GAEvent.CreateClientAdmin]: {
    category: 'Client Admin Created',
    action: 'Client Admin has been created',
    label: 'Client Admin has been created',
  },
};
