import { useFeatureValue } from '@growthbook/growthbook-react';
import { subDays } from 'date-fns';
import { useCallback, useEffect, useRef, useState } from 'react';

import {
  Billing,
  costOfSchedule,
  paymentForSchedule,
  sortSchedules,
  useOrderActions,
  useOrderState,
} from '../../../context';
import {
  Schedule,
  Step,
  useJobDraftActions,
  useJobDraftState,
} from '../../context';
import BottomBar from '../BottomBar';
import SummaryTable from '../SummaryTable';

import HolidayInfo from './HolidayInfo';
import ScheduleSection from './ScheduleSection';
import ScheduleSummary from './ScheduleSummary';
import SplitLayout from './SplitLayout';
import { Separator } from './styles';

import Avatar from '@/components/Avatar';
import Button from '@/components/Button';
import Card from '@/components/Card';
import LoadingState from '@/components/LoadingState';
import Stack from '@/components/Stack';
import { Body, Heading, Small, Subheading } from '@/components/Typography';
import { FEATURE_TOGGLE } from '@/constants/featuretoggle';
import { GAEvent } from '@/constants/gaevents';
import { PAST_DAYS, TIME_TO_REFRESH } from '@/constants/general';
import Text from '@/elements/Text';
import {
  useDefaultRateByCityQuery,
  useGetAccountOrdersQuery,
  useGetAccountPaymentMethodsQuery,
  useGetSurgeRatesQuery,
} from '@/graphql';
import useAuth from '@/hooks/useAuth';
import useMediaQuery from '@/hooks/useMediaQuery';
import { Role } from '@/routes/PrivateRoute';
import styled from '@/styles';
import { AccountRateTypeEnum, JobTypeEnum } from '@/types/graphql';
import useAnalytics from '@/util/analytics';
import {
  calculateTotalHours,
  formatDuration,
  formatISODate,
} from '@/util/datetime';
import { centsToCurrency } from '@/util/number';
import { checkIfCardIsCurrentPaymentMethod } from '@/util/payments';

const RightPanelWrapper = styled('div', {
  backgroundColor: '#EEFFEC',
  padding: '20px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  border: '1px solid #D3D3D3',
});

const AmountDiv = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
});

type Props = {
  setStep: (step: Step) => void;
};

export enum ShiftColors {
  GREEN = '#45a735',
  GREY = '#7A7A7A',
}

type FirstNoticeProps = {
  billing?: Billing;
};

export const FirstOrderNotice = ({ billing }: FirstNoticeProps) => {
  const { currentAdmin } = useAuth();
  const { data: paymentMethodData } = useGetAccountPaymentMethodsQuery({
    variables: {
      accountId: billing!.account.id,
    },
    pollInterval: TIME_TO_REFRESH,
  });

  const { data: accountOrdersData } = useGetAccountOrdersQuery({
    variables: {
      accountId: billing!.account.id,
      page: 1,
      perPage: 10,
    },
    pollInterval: TIME_TO_REFRESH,
  });

  const isTheFirsOrderOfThisAccount =
    accountOrdersData && accountOrdersData.account.orders.items.length === 0;

  const isCreditCardCurrentPaymentMethod =
    paymentMethodData &&
    checkIfCardIsCurrentPaymentMethod(
      paymentMethodData.accountPaymentMethods.cards,
      paymentMethodData.accountPaymentMethods.currentPaymentMethod,
    );

  if (
    currentAdmin!.role !== Role.TENANT_ADMIN &&
    isCreditCardCurrentPaymentMethod &&
    isTheFirsOrderOfThisAccount
  ) {
    return (
      <Card.Section>
        <Small>
          You will be charged 50% of the estimated total cost when your order is
          placed. Once the order has been completed and the hours approved, you
          will be charged the remaining balance.
        </Small>
      </Card.Section>
    );
  }
  return <></>;
};

const ScheduleStep = ({ setStep }: Props) => {
  const isTouchableBreakpoint = useMediaQuery('(max-width: 900px)');
  const phoneOnly = useMediaQuery('(max-width: 559px)');
  const removeHolidayRates = useFeatureValue(
    FEATURE_TOGGLE.RemoveHolidayRates,
    false,
  );

  const [continueNext, setContinueNext] = useState<boolean>(false);
  const [cost, setCost] = useState<number>();
  const [pay, setPay] = useState<number>();

  const { logEvent } = useAnalytics();

  const [startTime, setStartTime] = useState<number>(0);
  useEffect(() => {
    setStartTime(new Date().getTime());
  }, []);

  const showPricing = () => {
    // TODO add check from billing account type
    return billing?.account.rateType === AccountRateTypeEnum.STATIC;
  };

  const { setHolidays } = useOrderActions();
  const { billing, orderType } = useOrderState();
  const {
    address,
    schedules: draftSchedules,
    skill,
    postSetting,
  } = useJobDraftState();
  const { updateSchedules, updateCompletedStep, updateLastShift, updateRates } =
    useJobDraftActions();

  const { data: surgeRatesData, loading: loadingSurgeRates } =
    useGetSurgeRatesQuery({
      variables: { fromDate: formatISODate(subDays(new Date(), PAST_DAYS)) },
    });

  const holidays = () => {
    if (
      billing?.account.rateType === AccountRateTypeEnum.MARKUP &&
      removeHolidayRates
    ) {
      return [];
    } else {
      return surgeRatesData?.surgeRatesFromDate || [];
    }
  };

  useEffect(() => {
    setHolidays(holidays());
  }, [billing, surgeRatesData]);

  const { data: categoriesByDefaultRates, loading: loadingDefaultRates } =
    useDefaultRateByCityQuery({
      variables: {
        skillId: skill!.id,
        addressId: address!.id,
      },
    });

  const rateQuote = address!.rateQuotes!.find(
    (rateQuote) =>
      rateQuote.skill!.id === skill!.id &&
      rateQuote.account?.id === billing?.account.id,
  );

  const defaultRate = categoriesByDefaultRates?.defaultRatesBySkill;

  const rateQuoteDefault = {
    ...defaultRate,
    pay: defaultRate?.payRate,
    cost: defaultRate?.costRate,
  };

  const rateSkill = rateQuote || rateQuoteDefault;

  useEffect(() => {
    if (rateSkill && (!cost || !pay)) {
      setCost(rateSkill.cost ?? 0);
      setPay(rateSkill.pay ?? 0);
    }
  }, [rateSkill]);

  const handleSubmit = useCallback(() => {
    updateCompletedStep({
      completedStep: Step.SCHEDULE,
    });
    updateRates({
      payRate: rateSkill.pay ?? 0,
    });

    logEvent(
      orderType === JobTypeEnum.LTA ? GAEvent.StepTwoLta : GAEvent.StepTwoGig,
      billing?.account?.id,
      {
        time_spent: (new Date().getTime(), startTime) / 1000,
      },
    );
    setStep(Step.DETAILS);
  }, [draftSchedules]);

  const SummaryCard = useCallback(() => {
    const totalEstimate = centsToCurrency(
      draftSchedules.reduce((acc, el) => acc + costOfSchedule(el), 0),
    );

    const totaPayment = centsToCurrency(
      draftSchedules.reduce((acc, el) => acc + paymentForSchedule(el), 0),
    );

    const totalHours = formatDuration(
      calculateTotalHours({ schedules: draftSchedules }),
    );

    return (
      <Card>
        <RightPanelWrapper>
          <span style={{ display: 'flex' }}>
            <Avatar size="sm" src={skill!.imageUrl} />
            <span style={{ width: '10px' }} />
            <Heading>{skill!.name}</Heading>
          </span>
          {showPricing() && (
            <AmountDiv>
              <Heading>${totaPayment || ''}</Heading>
              <Text as="span" color={'lighter'}>
                EST. Pay
              </Text>
            </AmountDiv>
          )}
        </RightPanelWrapper>
        <Card.Section css={{ padding: phoneOnly ? '20px 5px !important' : '' }}>
          <SummaryTable
            handleJobsChange={handleJobsChange}
            postSetting={postSetting}
            schedules={draftSchedules}
            skillId={skill?.id}
            updateLastShift={(sch) => {
              updateLastShift({ lastShiftInfo: sch });
            }}
          />
        </Card.Section>
        {showPricing() && (
          <>
            <ScheduleSummary
              cost={cost ?? 0}
              pay={pay ?? 0}
              scheduleGroup={draftSchedules}
            />
            <HolidayInfo schedules={draftSchedules} />
            <FirstOrderNotice billing={billing} />
            <Card.Section>
              <Stack justify="apart">
                <Body>Total Hours</Body>
                <Subheading>
                  {totalHours !== 'NaN' ? totalHours : ''}
                </Subheading>
              </Stack>
              <Stack justify="apart">
                <Body weight={'semibold'}>Total Estimate</Body>
                <Subheading>${totalEstimate || ''}</Subheading>
              </Stack>
            </Card.Section>
          </>
        )}
      </Card>
    );
  }, [skill, draftSchedules, cost, pay]);

  const handleJobsChange = (shifts: Schedule[]) => {
    updateSchedules({ schedules: sortSchedules(shifts) });
  };

  return (
    <>
      {loadingSurgeRates || loadingDefaultRates ? (
        <Stack align="center" justify="center" style={{ height: '100%' }}>
          <LoadingState overlayColor="white" />
        </Stack>
      ) : (
        <>
          <SplitLayout>
            <SplitLayout.Left>
              <Stack
                vertical
                css={{
                  width: isTouchableBreakpoint ? '100%' : '90%',
                  padding: isTouchableBreakpoint ? '0 2px' : 0,
                }}
              >
                <Heading>{`${
                  orderType === JobTypeEnum.GIG ? 'Gig' : orderType
                } Job Schedule`}</Heading>
                <Stack vertical gap={24}>
                  <ScheduleSection
                    address={address}
                    customerId={billing?.customer?.id}
                    holidays={holidays()}
                    minCost={rateSkill?.cost ?? 0}
                    minPay={rateSkill?.pay ?? 0}
                    orderType={orderType}
                    schedules={draftSchedules}
                    setCost={setCost}
                    setDisabledButton={setContinueNext}
                    setPay={setPay}
                    showPricing={showPricing()}
                    onChange={handleJobsChange}
                  />

                  {isTouchableBreakpoint && (
                    <>
                      <Separator />
                      <SummaryCard />
                    </>
                  )}
                </Stack>
              </Stack>
            </SplitLayout.Left>

            {!isTouchableBreakpoint && (
              <SplitLayout.Right>
                <Stack vertical verticalGap={32}>
                  <SummaryCard />
                </Stack>
              </SplitLayout.Right>
            )}
          </SplitLayout>
          <BottomBar>
            <Button
              a11yLabel="Go back to previous step"
              appearance="outline"
              id="back-btn"
              label="Back"
              type="button"
              onClick={() =>
                orderType === JobTypeEnum.LTA
                  ? setStep(Step.SKILL)
                  : setStep(Step.PUBLISHING)
              }
            />
            <Button
              a11yLabel="Submit form"
              disabled={!draftSchedules.length || continueNext}
              id="continue-btn"
              label="Continue"
              onClick={handleSubmit}
            />
          </BottomBar>
        </>
      )}
    </>
  );
};

export default ScheduleStep;
