export const FEATURE_TOGGLE = {
  GroupChat: 'v2.2402.06_group-chat',
  BillingAccountOrderCreation: 'v2.2402.20_billing-account-order-creation',
  WorkerRating: 'worker-rating',
  HideBrokenReports: 'hide-broken-reports',
  ClientAdminToDeleteAccount:
    'v2.2405.14_allow-customer-admins-to-delete-account',
  DashboardApprovalStatusFeature: 'v2.2405.28_needs-approval-status',
  SelectNearestAddressRateFeature: 'v2.2404.30_select_nearest_address_rate',
  MarkupBasedPricingFeature: 'v2.2406.11_markup-based-pricing',
  SameWorkerAllShifts: 'v2.2401.09_same-worker-all-shifts',
  RemoveAddressCreationOnOrder:
    'v2.2405.28_remove-address-creation-during-order-creation',
  WorkerProfileRevamp: 'v2.2406.11_worker-profile-revamp',
  StaffingOrderCreation: 'v2.2406.25_worker-hire-and-invite-revamp',
  StaffingOrderAndJobScreen:
    'v2.2406.25_worker-hire-and-invite-revamp-order-and-job',
  RemoveHolidayRates: 'v2.2407.02_remove-holiday-rates',
  LockBillRates: 'v2.2408.06_lock_bill_rates',
  HideDisabledBillingAccount: 'v2.2408.06_disable_billing_accounts',
  TimesheetRevamp: 'v2.2408.06_revamp-timesheet',
  ForceUpdateClientApp: 'v2.2408.20_force-update-client-app',
  HelpdeskRole: 'v2.2409.03_helpdesk-role',
};
