import { CloseOutlined } from '@mui/icons-material';
import { Dialog, DialogTitle, IconButton, Stack } from '@mui/material';
import { useEffect, useState } from 'react';

import { getSortedWorkerSkillsList } from '../../../util';

import WorkerCertificatesSection from './WorkerCertificatesSection';
import WorkerDetailsSection from './WorkerDetailsSection';
import WorkerSkillsSection from './WorkerSkillsSection';
import { Container } from './styles';

import {
  GravyTrained,
  HeartIcon,
  HeartFillIcon,
  BlockIcon,
} from '@/assets/icons';
import CrownIcon from '@/assets/icons/CrownIcon';
import InactiveCrownIcon from '@/assets/icons/InactiveCrownIcon';
import Avatar from '@/components/Avatar';
import Button from '@/components/Button';
import Card from '@/components/Card';
import LoadingState from '@/components/LoadingState';
import { Subheading, Small } from '@/components/Typography';
import { V3Endpoints } from '@/constants/urls';
import { useGetWorkerProfileLazyQuery } from '@/graphql';
import useMediaQuery from '@/hooks/useMediaQuery';
import {
  JobWorkerItemFragment,
  RelationshipKindEnum,
  TaxTypeEnum,
} from '@/types/graphql';
import axiosClient from '@/util/axios/axiosClient';
import { ChatGroupGWUserType } from '@/util/axios/types';
import { containsGravyCertification } from '@/util/worker';

import './index.css';
import ls, { ACTIVE_CHAT_ID } from '@/util/localstorage';
import IconicButton from '@/components/IconicButton';

import { faHeart } from '@fortawesome/free-solid-svg-icons';
import PermissionComponent from '@/routes/PermissionComponent';
import BlockWorkerSection from './BlockWorkerSection';

type Props = {
  hideModal: () => void;
  ProfileInfo: JobWorkerItemFragment['worker'];
  isClientAdmin: boolean;
  workerId?: string;
  relationshipStatus: RelationshipKindEnum | null;
  createWorkerRelationship?: (
    reason: string,
    comments: string,
    kind: RelationshipKindEnum,
  ) => void;
  deleteWorkerRelationship?: (kind: RelationshipKindEnum) => void;
};

const ProfileModalRevamp = ({
  hideModal,
  ProfileInfo,
  isClientAdmin,
  workerId,
  relationshipStatus,
  createWorkerRelationship,
  deleteWorkerRelationship,
}: Props) => {
  const phoneOnly = useMediaQuery('(max-width: 559px)');
  const phoneStyles = phoneOnly
    ? { height: '90vh', width: '100%', borderRadius: '20px' }
    : {};
  const [buttonLoading, setButtonLoading] = useState(false);
  const [showBlockWorkerView, setShowBlockWorkerView] = useState(false);
  const [fetchProfile, { data, loading }] = useGetWorkerProfileLazyQuery();

  useEffect(() => {
    if (workerId) {
      fetchProfile({ variables: { workerId } });
    }
  }, [workerId]);

  ProfileInfo = data?.worker || ProfileInfo;

  const handleWorkerProfileNavigation = () => {
    window.open(`/workers/${ProfileInfo.id}`, '_blank');
  };

  const handleMessageWorkerClick = async () => {
    let name = `${ProfileInfo?.user?.firstName || ''} ${
      ProfileInfo?.user?.middleName || ''
    } ${ProfileInfo?.user?.lastName || ''}`;
    name = name.replace(/  +/g, ' ');
    try {
      setButtonLoading(true);
      const resp = await axiosClient.post(V3Endpoints.CREATE_CHAT_GROUP, {
        name,
        user: {
          id: parseInt(ProfileInfo.user.id!),
          type: ChatGroupGWUserType.WORKER,
        },
      });
      if (resp?.data?.id) {
        ls.set(ACTIVE_CHAT_ID, resp.data.id);
        window.open(`/inappchat`, '_blank');
      }
    } catch (e) {
      console.error(e);
    }
    setButtonLoading(false);
  };

  const isGravyCertified = containsGravyCertification(
    ProfileInfo?.activeCertificates,
  );

  const mergedWorkerSkillsList = getSortedWorkerSkillsList(
    ProfileInfo?.workerSkills || [],
  );
  const {
    averageRating,
    jobsDropped,
    jobsRatingCount,
    noShow,
    taxType,
    jobsWorked,
  } = ProfileInfo;
  const isPlus = taxType === TaxTypeEnum.TAX_1099;

  return (
    <Dialog
      PaperProps={{
        style: {
          height: 'auto',
          width: '600px',
          overflowX: 'hidden',
          borderRadius: '20px',
          maxHeight: 'calc(100% - 40px)',
          ...phoneStyles,
        },
      }}
      aria-labelledby="worker-profile-dialog"
      className="worker-profile-dialog-view"
      fullScreen={phoneOnly}
      open={true}
      sx={{
        '& .MuiDialog-container': phoneOnly
          ? { alignItems: 'end', marginBottom: '2px' }
          : {},
      }}
      onClose={hideModal}
    >
      <DialogTitle
        id="options-dialog-title"
        sx={{ m: 0, p: 2, fontWeight: '600' }}
      >
        <IconButton
          aria-label="close"
          sx={{
            position: 'absolute',
            right: 8,
            top: 6,
          }}
          onClick={hideModal}
        >
          <CloseOutlined />
        </IconButton>
      </DialogTitle>
      <Card.Section css={{ padding: '0px' }}>
        <Stack
          alignItems="center"
          direction={'column'}
          justifyContent="center"
          padding="20px"
          py={'8px'}
          spacing={{ md: 2.5, xs: 1.5 }}
        >
          <Stack
            alignItems="center"
            direction={'column'}
            justifyContent="center"
            spacing={{ xs: 1, sm: 0.7 }}
          >
            <Container>
              <Avatar
                rounded
                borderLg={isGravyCertified}
                css={{ boxSize: '180px' }}
                src={ProfileInfo.avatarUrl}
              />
              {isGravyCertified && (
                <GravyTrained
                  data-testid="gravy-certified-badge"
                  id="gravy-certified-badge"
                  sx={{
                    position: 'absolute',
                    right: 0,
                    bottom: 0,
                    top: 128,
                    left: 120,
                    width: '52px',
                    height: '52px',
                  }}
                />
              )}
            </Container>
            <Stack direction={'row'}>
              <Subheading
                css={{
                  fontSize: '24px',
                  textAlign: 'center',
                  wordBreak: 'break-word',
                }}
                weight="bold"
              >
                {`${ProfileInfo?.user?.firstName || ''} ${
                  ProfileInfo?.user?.middleName || ''
                } ${ProfileInfo?.user?.lastName || ''}`}
              </Subheading>
              {createWorkerRelationship ? (
                relationshipStatus === RelationshipKindEnum.FAVORITED ||
                relationshipStatus === null ? (
                  <PermissionComponent id="toggle-favorite-btn" showAsReadOnly>
                    <IconicButton
                      a11yLabel="Toggle Favorite"
                      appearance="clear"
                      transparentOnHover={true}
                      customIcon={
                        relationshipStatus ===
                        RelationshipKindEnum.FAVORITED ? (
                          <HeartFillIcon />
                        ) : (
                          <HeartIcon />
                        )
                      }
                      data-testid="toggle-favorite"
                      icon={faHeart}
                      id="toggle-favorite-btn"
                      size="sm"
                      style={{
                        marginTop: '5px',
                        marginLeft: '10px',
                        cursor: 'pointer',
                      }}
                      type="button"
                      onClick={() => {
                        if (
                          relationshipStatus === RelationshipKindEnum.FAVORITED
                        ) {
                          deleteWorkerRelationship &&
                            deleteWorkerRelationship(
                              RelationshipKindEnum.FAVORITED,
                            );
                        } else {
                          createWorkerRelationship(
                            '',
                            '',
                            RelationshipKindEnum.FAVORITED,
                          );
                        }
                      }}
                    />
                  </PermissionComponent>
                ) : (
                  <BlockIcon
                    style={{
                      marginTop: '5px',
                      marginLeft: '10px',
                    }}
                  />
                )
              ) : null}
            </Stack>

            <Stack alignItems="center" flexDirection="row" gap="12px">
              {isPlus ? (
                <InactiveCrownIcon fontSize="small" />
              ) : (
                <CrownIcon fontSize="small" />
              )}
              <Small css={{ fontStyle: 'italic', color: '#262626' }}>
                {isPlus ? 'Plus GravyWorker' : 'Flex Plus GravyWorker'}
              </Small>
            </Stack>
          </Stack>

          {!isClientAdmin && (
            <Stack
              flexDirection="row"
              gap="20px"
              style={{ marginBottom: '10px', marginTop: '16px' }}
              width="100%"
            >
              <Button
                a11yLabel="View Full Profile"
                data-testid="view-full-profile-button"
                label="View full profile"
                style={{
                  width: '100%',
                  height: '40px',
                  fontSize: '16px',
                }}
                type="button"
                onClick={handleWorkerProfileNavigation}
              />
              <Button
                a11yLabel="Message Worker"
                appearance="outline"
                data-testid="message-worker-button"
                isLoading={buttonLoading}
                label="Message Worker"
                style={{
                  borderColor: '#45A735',
                  fontSize: '16px',
                  color: '#45A735',
                  width: '100%',
                  height: '40px',
                }}
                type="button"
                onClick={handleMessageWorkerClick}
              />
            </Stack>
          )}

          <WorkerDetailsSection
            ProfileInfo={{
              averageRating,
              jobsDropped,
              jobsRatingCount,
              noShow,
              jobsWorked,
            }}
            isClientAdmin={isClientAdmin}
          />
        </Stack>
        {showBlockWorkerView ? (
          <BlockWorkerSection
            onCancel={() => setShowBlockWorkerView(false)}
            phoneOnly={phoneOnly}
            onBlock={async (values) => {
              createWorkerRelationship &&
                (await createWorkerRelationship(
                  values.reason,
                  values.comments,
                  RelationshipKindEnum.BLOCKED,
                ));
            }}
          />
        ) : (
          <>
            {loading ? (
              <LoadingState />
            ) : (
              <Stack marginBottom="24px">
                <WorkerSkillsSection workerSkills={mergedWorkerSkillsList} />
                <WorkerCertificatesSection
                  activeCertificates={ProfileInfo?.activeCertificates || []}
                />
              </Stack>
            )}
            {createWorkerRelationship && (
              <PermissionComponent id="toggle-block-btn">
                <Stack
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginBottom: '20px',
                  }}
                >
                  <IconicButton
                    a11yLabel="Toggle Block"
                    appearance="clear"
                    customIcon={<BlockIcon />}
                    data-testid="toggle-block"
                    icon={faHeart}
                    id="toggle-block-btn"
                    size="sm"
                    type="button"
                    transparentOnHover={true}
                    onClick={() => {
                      if (relationshipStatus === RelationshipKindEnum.BLOCKED) {
                        deleteWorkerRelationship &&
                          deleteWorkerRelationship(
                            RelationshipKindEnum.BLOCKED,
                          );
                      } else {
                        setShowBlockWorkerView(true);
                      }
                    }}
                    text={
                      relationshipStatus === RelationshipKindEnum.BLOCKED
                        ? 'Unblock Worker'
                        : 'Block Worker'
                    }
                    textStyle={{ color: '#DC1515' }}
                  />
                </Stack>
              </PermissionComponent>
            )}
          </>
        )}
      </Card.Section>
    </Dialog>
  );
};

export default ProfileModalRevamp;
