import { useReactiveVar } from '@apollo/client';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { useFeatureValue } from '@growthbook/growthbook-react';
import { addDays, formatISO as formatISO8601, format } from 'date-fns';
import { useCallback, useEffect, useMemo, useState } from 'react';

import DashboardFilters from '../DashboardFilters';
import TagsFilter from '../DashboardFilters/TagsFilter';

import ShiftGrid from './ShiftGrid';

import AccountStateError from '@/components/AccountStateError';
import Card from '@/components/Card';
import IconicButton from '@/components/IconicButton';
import Stack from '@/components/Stack';
import { Body } from '@/components/Typography';
import { setRegions } from '@/constants/filters';
import { TIME_TO_REFRESH } from '@/constants/general';
import { useListShiftsByWeekQuery, useRegionLazyQuery } from '@/graphql';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import useAuth from '@/hooks/useAuth';
import useFilters from '@/hooks/useFilters';
import useMediaQuery from '@/hooks/useMediaQuery';
import {
  selectRefetchDashboard,
  setRefetchDashboardData,
} from '@/store/slices/layoutSlice';
import {
  CurrentCustomerAdminFragment,
  FillStatusEnum,
  JobDashboardStatusEnum,
  JobFilterSetInput,
  VisibilityStatusEnum,
} from '@/types/graphql';
import {
  getAccountErrorTitle,
  getAccountErrorDesc,
  hasValidCustomerAccountStatus,
} from '@/util/accountstatus';
import { currentAdminVar, currentAgencyVar } from '@/util/apollo/cache';
import { MONDAY_OF_WEEK } from '@/util/constants';
import ls, { ACCOUNT_DISABLED } from '@/util/localstorage';

const initialFilters: JobFilterSetInput = {
  fillStatus: FillStatusEnum.ALL,
  visibility: VisibilityStatusEnum.ALL,
};

const ShiftWeekList = () => {
  const isFeatFlagDisabledCustomerLoginAllowedEnabled = useFeatureValue(
    'disabled-customer-login',
    false,
  );
  const currentAdminUser = useReactiveVar(
    currentAdminVar,
  ) as CurrentCustomerAdminFragment;
  const accountDisabled = ls.get(ACCOUNT_DISABLED) === 'true';
  const dispatch = useAppDispatch();
  const refetchData = useAppSelector(selectRefetchDashboard);

  function hasValidAccountStatus() {
    return hasValidCustomerAccountStatus(
      isFeatFlagDisabledCustomerLoginAllowedEnabled,
      currentAdminUser,
      accountDisabled,
    );
  }
  const currentAgency = useReactiveVar(currentAgencyVar);
  const { currentAdmin, isCurrentCustomerAdmin } = useAuth();

  const [startDate, setStartDate] = useState(MONDAY_OF_WEEK);

  const goBack = useCallback(
    () => setStartDate((date) => addDays(date, -7)),
    [],
  );

  const goForward = useCallback(
    () => setStartDate((date) => addDays(date, 7)),
    [],
  );

  const {
    debouncedQuery,
    filters,
    query: searchQuery,
    setQuery,
    setFilter,
    clearAll,
    clearFilter,
  } = useFilters<JobFilterSetInput>(
    isCurrentCustomerAdmin(currentAdmin)
      ? {
          ...initialFilters,
          customers: {
            value: [(currentAdmin as CurrentCustomerAdminFragment).customer.id],
          },
        }
      : initialFilters,
  );

  const finalFilters = useMemo<JobFilterSetInput>(() => {
    const value = { ...filters };

    if (debouncedQuery !== '') {
      value.query = { value: debouncedQuery };
    }

    return value;
  }, [debouncedQuery, filters]);

  const startDateISO = useMemo(
    () => formatISO8601(startDate, { representation: 'date' }),
    [startDate],
  );
  const { data, loading, refetch } = useListShiftsByWeekQuery({
    variables: {
      agencyId: currentAgency!.id,
      startDate: startDateISO,
      filters: finalFilters,
      timeZone: Intl?.DateTimeFormat()?.resolvedOptions().timeZone,
    },
    pollInterval: TIME_TO_REFRESH,
  });

  const clearAllFilters = () => {
    clearAll();
    setFilter('fillStatus', FillStatusEnum.ALL);
    setFilter('visibility', VisibilityStatusEnum.ALL);
    if (isCurrentCustomerAdmin(currentAdmin)) {
      setFilter('customers', { value: [currentAdmin.customer.id] });
    }
  };
  useEffect(() => {
    if (refetchData) {
      refetch?.();
      dispatch(setRefetchDashboardData(false));
    }
  }, [refetchData]);

  const [getRegions] = useRegionLazyQuery({
    onCompleted: (data) => {
      if (data) {
        setRegions(data.region);
      }
    },
  });

  useEffect(() => {
    getRegions({
      variables: {
        agencyId: currentAgency!.id,
      },
    });
  }, []);

  const hasCancelledFilterApplied = () => {
    const cancelledJobFilter = finalFilters?.jobDashboardStatus?.filter(
      (c) => c === JobDashboardStatusEnum.CANCELLED,
    );
    if (cancelledJobFilter !== null && cancelledJobFilter !== undefined) {
      return true;
    }

    return false;
  };

  const getShiftData = () => {
    if (!data) {
      return [];
    }

    if (!hasCancelledFilterApplied()) {
      const d = data?.agency.shiftsByWeek.filter((s) => {
        return s.job.cancelledAt === null || s.job.cancelledAt === undefined;
      });

      return d;
    } else {
      return data.agency.shiftsByWeek;
    }
  };

  const phoneOnly = useMediaQuery('(max-width: 559px)');

  return (
    <Card noRadius={phoneOnly}>
      <Card.Section>
        <Stack vertical>
          <Stack gap={phoneOnly ? 16 : 8} wrap={!!phoneOnly}>
            <Stack justify="apart">
              <Stack gap={16} justify={phoneOnly ? 'apart' : 'start'}>
                <IconicButton
                  a11yLabel="View previous week"
                  appearance={phoneOnly ? 'outline' : 'clear'}
                  icon={faChevronLeft}
                  id="btn_previous_week"
                  size={phoneOnly ? 'md' : 'xs'}
                  onClick={goBack}
                />
                <Body>
                  {format(startDate, 'MMM do')} &mdash;{' '}
                  {format(addDays(startDate, 6), 'MMM do')}
                </Body>
                <IconicButton
                  a11yLabel="View next week"
                  appearance={phoneOnly ? 'outline' : 'clear'}
                  icon={faChevronRight}
                  id="btn_next_week"
                  size={phoneOnly ? 'md' : 'xs'}
                  onClick={goForward}
                />
              </Stack>
            </Stack>
            <DashboardFilters
              filters={filters}
              query={searchQuery}
              onChangeFilter={setFilter}
              onClearAll={clearAllFilters}
              onClearFilter={clearFilter}
              onQueryChange={setQuery}
            />
          </Stack>
          {hasValidAccountStatus() && (
            <Stack>
              <TagsFilter
                filters={filters}
                onChangeFilter={setFilter}
                onClearAll={clearAllFilters}
                onClearFilter={clearFilter}
              />
            </Stack>
          )}
        </Stack>
        {!hasValidAccountStatus() && (
          <AccountStateError
            message={getAccountErrorDesc(accountDisabled, currentAdminUser)}
            title={getAccountErrorTitle(accountDisabled, currentAdminUser)}
          />
        )}
      </Card.Section>

      {hasValidAccountStatus() && (
        <ShiftGrid
          loading={loading}
          shifts={getShiftData()}
          startDate={startDate}
        />
      )}
    </Card>
  );
};

export default ShiftWeekList;
