import { QueryResult, useReactiveVar } from '@apollo/client';
import {
  faSortNumericDown,
  faSortNumericUpAlt,
} from '@fortawesome/free-solid-svg-icons';
import { useFeatureValue } from '@growthbook/growthbook-react';
import { addYears, format } from 'date-fns';
import { useEffect, useMemo, useState, ChangeEvent, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import OrderFilters from './OrderFilters';

import AccountStateError from '@/components/AccountStateError';
import Avatar from '@/components/Avatar';
import AvatarGroupComponent, {
  TypeAvatarGroups,
} from '@/components/AvatarGroupComponent';
import Badge from '@/components/Badge';
import Card from '@/components/Card';
import LoadingState from '@/components/LoadingState';
import { LtaGigMenu } from '@/components/LtaGigMenu';
import Page from '@/components/Page';
import Paginator from '@/components/Paginator/Paginator';
import QueryEmptyState from '@/components/QueryEmptyState';
import Stack from '@/components/Stack';
import Table from '@/components/Table';
import TextStack from '@/components/TextStack';
import {
  Body,
  Small,
  ParagraphProps as BodyVariants,
} from '@/components/Typography';
import { FEATURE_TOGGLE } from '@/constants/featuretoggle';
import { TIME_TO_REFRESH } from '@/constants/general';
import Icon from '@/elements/Icon';
import Link from '@/elements/Link';
import { TableCell, TableHead, TableHeading, TableRow } from '@/elements/Table';
import { useListOrdersLazyQuery } from '@/graphql';
import useAuth from '@/hooks/useAuth';
import useFilters from '@/hooks/useFilters';
import useMediaQuery from '@/hooks/useMediaQuery';
import { getTaxTypeLabel } from '@/routes/Agency/Order/util';
import ROUTES from '@/routes/routes';
import {
  CompareOperatorEnum,
  OrderFilterSetInput,
  OrderSortKeyEnum,
  Scalars,
  CurrentCustomerAdminFragment,
  JobTypeEnum,
} from '@/types/graphql';
import {
  getAccountErrorTitle,
  getAccountErrorDesc,
  hasValidCustomerAccountStatus,
} from '@/util/accountstatus';
import { currentAdminVar } from '@/util/apollo/cache';
import {
  approvalStatusResolver,
  getFilledJobsCount,
  getCancelledJobsCount,
  getSkillsImages,
} from '@/util/job';
import ls, { ACCOUNT_DISABLED } from '@/util/localstorage';
import { pluralizeNumber } from '@/util/number';

export const oneHundredYearsAgo = addYears(new Date(), -100).toISOString();

type SortByFilter = {
  sort: OrderSortKeyEnum;
  reverse: Scalars['Boolean'];
};

const initialFilters: OrderFilterSetInput = {};

const initialSortBy: SortByFilter = {
  sort: OrderSortKeyEnum.START_AT,
  reverse: false,
};

const headerFields = [
  'ID',
  'Client',
  'Timeframe',
  'Approval status',
  'Filled jobs',
  'Skills',
];

const Orders = () => {
  const {
    currentAdmin,
    currentAdminIsCustomerAdmin,
    isCurrentCustomerAdmin,
    currentAgency,
  } = useAuth();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [sortBy, setSortBy] = useState<SortByFilter>(initialSortBy);
  const phoneOnly = useMediaQuery('(max-width: 559px)');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const ref = useRef(null);
  const navigate = useNavigate();

  const {
    debouncedQuery,
    filters,
    query: searchQuery,
    clearAll,
    clearFilter,
    setFilter,
    setQuery,
  } = useFilters<OrderFilterSetInput>(initialFilters);

  const staffingOrderCreation = useFeatureValue(
    FEATURE_TOGGLE.StaffingOrderCreation,
    false,
  );
  const orderFolder = staffingOrderCreation ? 'creategigorder' : 'creategig';
  const isFeatFlagDisabledCustomerLoginAllowedEnabled = useFeatureValue(
    'disabled-customer-login',
    false,
  );
  const currentAdminUser = useReactiveVar(
    currentAdminVar,
  ) as CurrentCustomerAdminFragment;
  const accountDisabled = ls.get(ACCOUNT_DISABLED) === 'true';

  function hasValidAccountStatus(): boolean {
    return hasValidCustomerAccountStatus(
      isFeatFlagDisabledCustomerLoginAllowedEnabled,
      currentAdminUser,
      accountDisabled,
    );
  }

  const finalFilters = useMemo<OrderFilterSetInput>(() => {
    const value = { ...filters };

    if (debouncedQuery !== '') {
      value.query = { value: debouncedQuery };
    }

    return value;
  }, [debouncedQuery, filters]);

  const sorting = (value: SortByFilter['sort']) => {
    setSortBy((prevValue) => ({
      sort: value,
      reverse: prevValue.sort === value ? !prevValue.reverse : true,
    }));
  };

  const clearAllFilters = () => {
    clearAll();
    if (isCurrentCustomerAdmin(currentAdmin)) {
      setFilter('customer', { value: currentAdmin.customer.id });
    }
  };

  const [handleListOrders, query] = useListOrdersLazyQuery({
    fetchPolicy: 'network-only',
    pollInterval: TIME_TO_REFRESH,
  });

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleNumberItemsChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setCurrentPage(1);
    setItemsPerPage(parseInt(event.target.value));
  };

  const items =
    !query.loading && query.data ? query.data.agency.orders.items : [];
  const totalItems =
    !query.loading && query.data
      ? query.data.agency.orders.pageInfo.totalItems
      : 0;

  useEffect(() => {
    setFilter('startAt', {
      op: CompareOperatorEnum.LT,
      value: new Date().toISOString(),
    });

    setFilter('endAt', {
      op: CompareOperatorEnum.GT,
      value: new Date().toISOString(),
    });

    if (isCurrentCustomerAdmin(currentAdmin)) {
      setFilter('customer', { value: currentAdmin.customer.id });
    }
  }, [currentAdmin]);

  useEffect(() => {
    handleListOrders({
      variables: {
        agencyId: currentAgency!.id,
        filters: finalFilters,
        page: currentPage,
        perPage: itemsPerPage,
        sortKey: sortBy.sort,
        reverse: sortBy.reverse,
      },
    });
  }, [finalFilters, sortBy, currentPage, itemsPerPage]);

  useEffect(() => {
    setCurrentPage(1);
  }, [finalFilters]);

  const ordersListingOnNewOrder = () => {
    if (
      isCurrentCustomerAdmin(currentAdmin) &&
      !currentAdmin.customer?.ltaAllowed
    ) {
      navigate(`/orders/${orderFolder}?type=${JobTypeEnum.GIG}`);
    } else {
      setAnchorEl(ref?.current);
    }
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <Page
      footer={
        items.length > 0 && (
          <Paginator
            currentPage={currentPage}
            handleNumberItemsChange={handleNumberItemsChange}
            handlePageChange={handlePageChange}
            itemsLength={totalItems}
            itemsPerPage={itemsPerPage}
          />
        )
      }
      headerPadding={!!phoneOnly}
      noPadding={!!phoneOnly}
      primaryAction={{
        a11yLabel: 'Place a new order',
        label: 'Place new order',
        onAction: ordersListingOnNewOrder,
        id: 'create-new-order',
        ref,
        disabled: !hasValidAccountStatus(),
      }}
      size={phoneOnly ? 'full' : 'md'}
      title="Orders"
    >
      <LtaGigMenu
        anchorEl={anchorEl}
        handleCloseMenu={handleCloseMenu}
        open={open}
      />
      <Card noRadius={phoneOnly}>
        <Card.Section>
          <OrderFilters
            filters={filters}
            hasValidAccountStatus={hasValidAccountStatus()}
            query={searchQuery}
            onChangeFilter={setFilter}
            onClearAll={clearAllFilters}
            onClearFilter={clearFilter}
            onQueryChange={setQuery}
          />
        </Card.Section>
        {!hasValidAccountStatus() && (
          <AccountStateError
            message={getAccountErrorDesc(accountDisabled, currentAdminUser)}
            title={getAccountErrorTitle(accountDisabled, currentAdminUser)}
          />
        )}
        {hasValidAccountStatus() && (
          <Table>
            {!phoneOnly && (
              <TableHead>
                <TableRow>
                  <TableHeading
                    id="order-id-col"
                    touchable="true"
                    onClick={() => sorting(OrderSortKeyEnum.ID)}
                  >
                    {`ID `}
                    <Icon
                      fixedWidth
                      icon={
                        sortBy.reverse && sortBy.sort === OrderSortKeyEnum.ID
                          ? faSortNumericUpAlt
                          : faSortNumericDown
                      }
                      size={'sm'}
                    />
                  </TableHeading>
                  <TableHeading>Client</TableHeading>
                  <TableHeading
                    touchable="true"
                    onClick={() => sorting(OrderSortKeyEnum.START_AT)}
                  >
                    {`Timeframe `}
                    <Icon
                      fixedWidth
                      icon={
                        sortBy.reverse &&
                        sortBy.sort === OrderSortKeyEnum.START_AT
                          ? faSortNumericUpAlt
                          : faSortNumericDown
                      }
                      size={'sm'}
                    />
                  </TableHeading>
                  <TableHeading> Approval Status</TableHeading>
                  <TableHeading>Filled Jobs</TableHeading>
                  <TableHeading align="center">Skills</TableHeading>
                </TableRow>
              </TableHead>
            )}
            <tbody>
              {items.map((item, index) => {
                const pluralizedJobsCount = pluralizeNumber(
                  item.jobsCount,
                  'job',
                  'jobs',
                );
                const filledJobsCount = getFilledJobsCount(item.jobs);
                const filledJobsContent = `${filledJobsCount}/${item.jobsCount} ${pluralizedJobsCount}`;
                const cancelledJobsCount = getCancelledJobsCount(item.jobs);
                const cancelledJobsContent = `${cancelledJobsCount}/${item.jobsCount} cancelled`;
                const hasCancelledJobs = cancelledJobsCount !== 0;
                const { 0: firstJob } = item.jobs;
                const { taxType } = firstJob;
                const taxTypeLabel = getTaxTypeLabel(taxType);
                const jobApprovalStatus = approvalStatusResolver(item.status);
                const skillImagesArray = getSkillsImages(item.jobs);

                return (
                  <TableRow key={index} mobile={phoneOnly}>
                    <TableCell noCellTitle stack>
                      <Stack
                        align={'end'}
                        gap={1}
                        justify={'apart'}
                        vertical={!phoneOnly}
                      >
                        <Link id={`order_${index}`} to={item.id}>
                          {phoneOnly && (
                            <Small size={'lg'} weight={'semibold'}>
                              ID:{' '}
                            </Small>
                          )}
                          <Small
                            color={'theme'}
                            size={phoneOnly ? 'lg' : 'md'}
                            weight={phoneOnly ? 'semibold' : 'normal'}
                          >
                            #{item.id}
                          </Small>
                        </Link>
                        {taxTypeLabel && !currentAdminIsCustomerAdmin && (
                          <Badge label={taxTypeLabel} status="neutral" />
                        )}
                      </Stack>
                    </TableCell>
                    <TableCell noCellTitle stack={phoneOnly}>
                      <Stack gap={!phoneOnly ? 20 : 8}>
                        <div>
                          <Avatar
                            firstName={item.account.customer.name}
                            size={!phoneOnly ? 'sm' : 'm'}
                            src={item.account.customer.logoUrl}
                          />
                        </div>
                        <TextStack spacing="tight">
                          <Link
                            to={
                              currentAdminIsCustomerAdmin
                                ? `../${ROUTES.myCompany}`
                                : `../${ROUTES.clients}/${item.account.customer.id}`
                            }
                          >
                            <Body color="inherit" weight="medium">
                              {item.account.customer.name}
                            </Body>
                          </Link>

                          <Link
                            appearance="subtle"
                            to={
                              currentAdminIsCustomerAdmin
                                ? `../${ROUTES.myCompany}/accounts/${item.account.id}`
                                : `../${ROUTES.clients}/${item.account.customer.id}/accounts/${item.account.id}`
                            }
                          >
                            <Small>{item.account.name}</Small>
                          </Link>
                        </TextStack>
                      </Stack>
                    </TableCell>
                    <TableCell data-celltitle={'Timeframe: '} stack={phoneOnly}>
                      {format(new Date(item.startAt), 'PP')} &mdash;{' '}
                      {format(new Date(item.endAt), 'PP')}
                    </TableCell>
                    <TableCell
                      data-celltitle={'Approval Status: '}
                      stack={phoneOnly}
                    >
                      <Body
                        color={jobApprovalStatus.color as BodyVariants['color']}
                        size={!phoneOnly ? 'md' : 'sm'}
                        weight="medium"
                      >
                        {jobApprovalStatus.label}
                      </Body>
                    </TableCell>
                    <TableCell
                      data-celltitle={'Filled Jobs: '}
                      stack={phoneOnly}
                    >
                      {!phoneOnly ? (
                        <TextStack>
                          {filledJobsContent}
                          {hasCancelledJobs && (
                            <Badge
                              label={cancelledJobsContent}
                              status="danger"
                            />
                          )}
                        </TextStack>
                      ) : (
                        <Stack align={'center'}>
                          <Badge label={filledJobsContent} status="success" />
                          {hasCancelledJobs && (
                            <Badge
                              label={cancelledJobsContent}
                              status="danger"
                            />
                          )}
                        </Stack>
                      )}
                    </TableCell>
                    <TableCell data-celltitle={'Skills: '} stack={phoneOnly}>
                      <Stack>
                        <AvatarGroupComponent
                          items={skillImagesArray}
                          maxLimitToShow={4}
                          small={phoneOnly}
                          type={TypeAvatarGroups.Skill}
                        />
                      </Stack>
                    </TableCell>
                  </TableRow>
                );
              })}
              {query.loading && (
                <TableRow>
                  <TableCell align="center" colSpan={headerFields.length}>
                    <LoadingState overlayColor="white" />
                  </TableCell>
                </TableRow>
              )}
            </tbody>
          </Table>
        )}
        {hasValidAccountStatus() && !query.loading && items.length === 0 && (
          <QueryEmptyState
            query={query as QueryResult}
            text="No orders were found, create one above."
            title="No orders"
          />
        )}
      </Card>
    </Page>
  );
};

export default Orders;
