import { NavigateNext, NavigateBefore } from '@mui/icons-material';
import { IconButton, LinearProgress } from '@mui/material';
import { parse } from 'date-fns';
import { useEffect, useMemo, useRef, useState } from 'react';

import { useOrderState } from '../../../context';
import { useJobDraftActions, useJobDraftState } from '../../context';

import BreakCrumbHeaders from './Common/BreadCrumbHeaders';
import StaffingShiftsCardsSection from './Common/StaffingShiftsCardsSection';
import StaffingTabs from './Common/StaffingTabs';
import {
  NotFoundAlert,
  getHiredUpdatedSchedules,
  getMainHeading,
  getTabName,
  getTabValue,
} from './Common/StaffingUtils';
import StaffingWorkerProfileRow from './Common/StaffingWorkerProfileRow';

import Stack from '@/components/Stack';
import TableHeader from '@/components/TableHeader';
import TextInput from '@/components/TextInput';
import { Heading } from '@/components/Typography';
import { GAEvent } from '@/constants/gaevents';
import { WORKERS_LISTING_LIMIT } from '@/constants/general';
import { V3Endpoints } from '@/constants/urls';
import Table from '@/elements/Table';
import useAuth from '@/hooks/useAuth';
import useDebouncedValue from '@/hooks/useDebouncedValue';
import useMediaQuery from '@/hooks/useMediaQuery';
import { JobTypeEnum } from '@/types/graphql';
import axiosClient from '@/util/axios/axiosClient';

import './index.css';
import useAnalytics from '@/util/analytics';

type Props = {
  onClickJobAccess: () => void;
};

const MainInviteWorkersPage = ({ onClickJobAccess }: Props) => {
  const jobState = useJobDraftState();
  const { orderType, billing } = useOrderState();
  const { updateSchedules } = useJobDraftActions();
  const { schedules, skill, address } = jobState;
  const [tab, setTab] = useState(0);
  const { currentAdminIsCustomerAdmin: isClientAdmin } = useAuth();
  const [selectedSchedules, setSelectedSchedules] = useState<number[]>([]);
  const [workersList, setWorkersList] = useState<any[]>([]);
  const [search, setSearch] = useState('');
  const debSearch = useDebouncedValue(search, 400);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [isShowNavIcon, setIsShowNavIcon] = useState(false);
  const [disabledIcon, setDisabledIcon] = useState('left');

  const { logEvent } = useAnalytics();

  const container = document.getElementById('create-order-modal');
  const isLTA = orderType === JobTypeEnum.LTA;
  const skillName = jobState.skill?.name;

  const selectedSchedulesInfo = useMemo(() => {
    return schedules.filter((sch) => selectedSchedules.includes(sch.groupId));
  }, [schedules, selectedSchedules]);

  const containerRef = useRef<any | null>(null);

  const isPastScheduleJob = (sch) => {
    return (
      parse(sch.startTime, 'HH:mm', sch.dateRange.startDate!).getTime() <
      new Date().getTime()
    );
  };
  const allSchedulesArePast = () => {
    return schedules.every((sch) => {
      return isPastScheduleJob(sch);
    });
  };

  const allSelectedSchedulesArePast = () => {
    return selectedSchedulesInfo.every((sch) => {
      return isPastScheduleJob(sch);
    });
  };

  const TABLE_HEADERS = [
    { name: 'Worker Details', style: { color: '#262626' } },
    { name: skillName + ' Shifts', style: { width: '16%', color: '#262626' } },
    { name: 'Shifts With You', style: { width: '12%', color: '#262626' } },
    { name: 'Action', style: { width: '14%', color: '#262626' } },
  ];

  /** **226 is the each shift section width and 20 is the gap between them(schedules)****/
  const schedulesContainerWidth =
    schedules.length * 226 + 20 * (schedules.length - 1);

  const fetchWorkers = async (page: number) => {
    setLoading(true);
    try {
      const res = await axiosClient.post(V3Endpoints.GET_WORKERS_LIST, {
        skillId: Number(skill?.id),
        customerId: Number(billing?.customer.id),
        addressId: Number(address?.id),
        page,
        perPage: WORKERS_LISTING_LIMIT,
        search: debSearch,
        tab: getTabValue(tab, !!skill?.gravyTrained),
        workerType: isLTA ? 'lta' : 'gig',
        taxType: billing?.customer.jobTaxType || 'all',
      });
      const respData = res?.data?.data || [];
      setWorkersList((data) => (page > 1 ? [...data, ...respData] : respData));
      setHasMore(respData.length === WORKERS_LISTING_LIMIT);
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };

  const handleScroll = () => {
    if (
      container &&
      container.scrollTop + container.clientHeight >=
        container.scrollHeight - 1 &&
      hasMore &&
      !loading
    ) {
      if (workersList.length < WORKERS_LISTING_LIMIT) return;
      setPage(page + 1);
      fetchWorkers(page + 1);
    }
  };

  useEffect(() => {
    if (debSearch) {
      logEvent(GAEvent.WorkerListSearch);
    }
    setPage(1);
    fetchWorkers(1);
  }, [tab, debSearch]);

  useEffect(() => {
    /** if shifts container width is greater then current window inner width minus(some page's padding)**/
    const isShowNextIcon = schedulesContainerWidth > window.innerWidth - 80;
    setIsShowNavIcon(isShowNextIcon);
  }, [schedules]);

  const inviteButtonHandler = (worker: Record<string, any>, mode?: string) => {
    const updSchedules = schedules.map((sch) => {
      if (selectedSchedules.includes(sch.groupId) && !isPastScheduleJob(sch)) {
        if (mode === 'remove') {
          sch.selectedWorkers = sch.selectedWorkers.filter(
            (w) => w.id !== worker.id,
          );
        } else if (!sch.selectedWorkers.find((w) => w.id === worker.id)) {
          sch.selectedWorkers = [...sch.selectedWorkers, worker];
        }
      }
      return sch;
    });
    updateSchedules({ schedules: updSchedules });
    logEvent(GAEvent.JobInviteWorker, worker.id, {
      inviteSentFrom: 'Job Creation Page',
      tab: getTabValue(tab, !!skill?.gravyTrained),
    });
  };

  const hireButtonHandler = (worker: Record<string, any>, mode?: string) => {
    const { removeSelection, updatedSchedules } = getHiredUpdatedSchedules(
      schedules,
      selectedSchedules,
      worker,
      mode,
    );
    updateSchedules({ schedules: updatedSchedules });
    if (removeSelection.length > 0) {
      setSelectedSchedules(
        selectedSchedules.filter((id) => !removeSelection.includes(id)),
      );
    }
    logEvent(GAEvent.JobHireWorker, worker.id, {
      hiredFrom: 'Job Creation Page',
      tab: getTabValue(tab, !!skill?.gravyTrained),
    });
  };

  const checkWorkerHiredOrInvited = (workerId: string, action = '') => {
    if (selectedSchedulesInfo.length === 0) return false;
    const checkProperty =
      action === 'hire' ? 'hiredWorkers' : 'selectedWorkers';

    for (let i = 0; i < selectedSchedulesInfo.length; i++) {
      const sch = selectedSchedulesInfo[i];
      if (!sch[checkProperty].find((w) => w.id === workerId)) {
        return false;
      }
    }
    return true;
  };

  const shiftCardSelectionHandler = (scheduleId: number) => {
    const idx = selectedSchedules.indexOf(scheduleId);
    if (idx === -1) {
      setSelectedSchedules([...selectedSchedules, scheduleId]);
    } else {
      setSelectedSchedules(selectedSchedules.filter((id) => id !== scheduleId));
    }
  };
  const phoneOnly = useMediaQuery('(max-width: 559px)');

  const shiftsNavHandler = (rightNav = true) => {
    if (containerRef.current) {
      if (rightNav) {
        containerRef.current.scrollBy({ left: 500, behavior: 'smooth' });
      } else {
        containerRef.current.scrollBy({ left: -500, behavior: 'smooth' });
      }
      setTimeout(() => {
        if (containerRef.current.scrollLeft === 0) {
          setDisabledIcon('left');
        } else if (
          containerRef.current.scrollLeft +
            containerRef.current.clientWidth +
            1 >=
          containerRef.current.scrollWidth
        ) {
          setDisabledIcon('right');
        } else {
          setDisabledIcon('');
        }
      }, 550);
    }
  };

  useEffect(() => {
    if (container) {
      container.addEventListener('scroll', handleScroll);
      return () => container.removeEventListener('scroll', handleScroll);
    }
  }, [hasMore, loading, debSearch]);

  return (
    <>
      <BreakCrumbHeaders
        step={2}
        styles={{
          paddingLeft: phoneOnly ? 0 : '32px',
          marginBottom: '2px',
          top: '-5px',
        }}
        onClickJobAccess={() =>
          orderType !== JobTypeEnum.LTA && onClickJobAccess()
        }
      />
      <Stack
        css={{
          padding: phoneOnly ? '0px' : '0 32px',
          flexDirection: 'column',
        }}
        gap={10}
      >
        <Heading css={{ width: '100%', marginTop: '6px' }}>
          {getMainHeading(skillName, isClientAdmin, isLTA)}
        </Heading>

        {/** ---shifts cards section***/}
        {schedules.length > 0 && (
          <div
            ref={containerRef}
            className="hide-scroll"
            style={{
              width: isShowNavIcon ? '97%' : '100%',
              position: phoneOnly ? 'initial' : 'revert',
              overflow: phoneOnly ? 'auto hidden' : 'hidden',
            }}
          >
            <Stack
              className="hide-scroll"
              css={{
                width: '100%',
                marginTop: '10px',
                marginBottom: '10px',
                flexDirection: 'row',
              }}
              gap={20}
            >
              {schedules.map((sch, idx) => (
                <StaffingShiftsCardsSection
                  key={idx}
                  isClientAdmin={isClientAdmin}
                  isLTA={isLTA}
                  schedule={sch}
                  selectedSchedules={selectedSchedules}
                  onShiftCardSelection={shiftCardSelectionHandler}
                />
              ))}
            </Stack>
            {!phoneOnly && isShowNavIcon && (
              <IconButton
                disabled={disabledIcon === 'right'}
                sx={{
                  position: 'absolute',
                  top: '160px',
                  right: '1%',
                  border: '1px solid #D3D3D3',
                  borderRadius: '20px',
                  cursor: 'pointer',
                  padding: '0px',
                }}
                onClick={() => shiftsNavHandler()}
              >
                <NavigateNext />
              </IconButton>
            )}
            {!phoneOnly && isShowNavIcon && (
              <IconButton
                disabled={disabledIcon === 'left'}
                sx={{
                  position: 'absolute',
                  top: '160px',
                  left: '1%',
                  border: '1px solid #D3D3D3',
                  borderRadius: '20px',
                  cursor: 'pointer',
                  padding: '0px',
                }}
                onClick={() => shiftsNavHandler(false)}
              >
                <NavigateBefore />
              </IconButton>
            )}
          </div>
        )}

        {!isLTA && (
          <StaffingTabs
            addressId={address?.id!}
            custId={billing?.customer.id!}
            isLTA={isLTA}
            jobTaxType={billing?.customer.jobTaxType!}
            setTab={(tab) => {
              setSearch('');
              setTab(tab);
            }}
            skill={skill!}
            tab={tab}
          />
        )}
        {/** ---workers table***/}
        <Stack
          vertical
          css={{ flexDirection: 'row', marginTop: '12px' }}
          gap={16}
        >
          {!phoneOnly && (
            <Heading css={{ width: '100%' }}>
              {isLTA ? 'LTA' : getTabName(tab, !!skill?.gravyTrained)}{' '}
              GravyWorkers
            </Heading>
          )}
          <div style={{ width: phoneOnly ? '100%' : '55%' }}>
            <TextInput
              id="search-GravyWorkers"
              placeholder="Search for GravyWorkers"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </Stack>
        <div style={{ width: '100%', marginTop: phoneOnly ? '18px' : '20px' }}>
          {loading && <LinearProgress />}
          <Table
            css={phoneOnly ? { tableLayout: 'fixed', overflowX: 'clip' } : {}}
          >
            {!phoneOnly && (
              <TableHeader fields={TABLE_HEADERS} headerBgColor={'#EBEBEB'} />
            )}
            <tbody>
              {workersList.map((w) => (
                <StaffingWorkerProfileRow
                  key={w.id}
                  buttonsDisableCheck={selectedSchedules.length === 0}
                  hireButtonClick={hireButtonHandler}
                  inviteButtonClick={inviteButtonHandler}
                  inviteButtonDisable={
                    selectedSchedules.length === 0 ||
                    allSelectedSchedulesArePast()
                  }
                  isClientAdmin={isClientAdmin}
                  isHiredButton={checkWorkerHiredOrInvited(w.id, 'hire')}
                  isInvitedButton={checkWorkerHiredOrInvited(w.id)}
                  isLTA={isLTA || allSchedulesArePast()}
                  skillName={skill?.name!}
                  worker={w}
                />
              ))}
            </tbody>
          </Table>
          {!loading && workersList.length === 0 && <NotFoundAlert tab={tab} />}
          {loading && workersList.length >= WORKERS_LISTING_LIMIT && (
            <LinearProgress />
          )}
        </div>
      </Stack>
    </>
  );
};

export default MainInviteWorkersPage;
