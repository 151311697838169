import { useReactiveVar } from '@apollo/client';
import { useFeatureValue } from '@growthbook/growthbook-react';
import {
  ArrowBack,
  Campaign,
  KeyboardArrowDown,
  SendOutlined,
} from '@mui/icons-material';
import {
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Popover,
  Stack,
  TextField,
  Alert,
  LinearProgress,
} from '@mui/material';
import { addDays, format, parse, parseISO } from 'date-fns';
import dayjs from 'dayjs';
import React, { useEffect, useMemo, useState } from 'react';

import { isValidUSZip } from '../utils';

import CheckboxList from './CheckboxList';
import SelectSkillsModal from './SelectSkillsModal';

import ShiftTimePicker from '@/components/ShiftTimePicker';
import { Body, Heading } from '@/components/Typography';
import { FEATURE_TOGGLE } from '@/constants/featuretoggle';
import { V3Endpoints } from '@/constants/urls';
import Input from '@/elements/Input';
import { useListAgencySkillsQuery } from '@/graphql';
import useMediaQuery from '@/hooks/useMediaQuery';
import styled from '@/styles';
import breakpoints from '@/styles/breakpoints';
import colors, { IconColor, theme } from '@/styles/colors';
import { currentAgencyVar } from '@/util/apollo/cache';
import axiosClient from '@/util/axios/axiosClient';

const Wrapper = styled('div', {
  maxHeight: '91vh',
  overflow: 'auto',
  width: '90%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  '@phoneOnly': {
    width: '100%',
    overflowX: 'clip',
  },
});
const Section = styled('div', {
  backgroundColor: 'white',
  width: '100%',
  padding: '14px 22px',
  height: '64px',
  '@phoneOnly': {
    padding: '10px 12px',
    height: '54px',
  },
});

const checkboxStyles = {
  '&.Mui-checked': {
    color: theme.themeDefault,
  },
};
const InputStyles = {
  marginLeft: '10px',
  verticalAlign: 'middle',
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'white',
    },
    '&:hover fieldset': {
      borderColor: 'white',
    },
    '&.Mui-focused fieldset': {
      borderColor: colors.themeLight,
    },
  },
};
interface Skill {
  id: string;
  name: string;
}
interface BulkMsgProps {
  handleBack: () => void;
  handleBulkSuccess: () => void;
}

const SendBulkMessage = ({ handleBack, handleBulkSuccess }: BulkMsgProps) => {
  const phoneOnly = useMediaQuery(breakpoints.phoneOnly);
  const currentAgency = useReactiveVar(currentAgencyVar);
  const { data } = useListAgencySkillsQuery({
    variables: { agencyId: currentAgency!.id },
  });
  const [allSkills, setAllSkills] = useState<Skill[]>([]);
  const [selectedSkills, setSelectedSkills] = useState<Skill[]>([]);
  const [selectedTaxTypes, setSelectedTaxTypes] = useState<string[]>([
    'w2',
    '1099',
  ]);
  const [miles, setMiles] = useState<number | undefined>();
  const [zipCode, setZipCode] = useState('');
  const [showskillsModal, setShowSkillsModal] = useState(false);
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState('');
  const [expDate, setExpDate] = useState(
    format(addDays(new Date(), 3), 'yyyy-MM-dd'),
  );
  const [expTime, setExpTime] = useState('23:59');

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (phoneOnly) {
      setShowSkillsModal(true);
      return;
    }
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (data?.agency) {
      const items = data.agency.skillCategories
        .flatMap((category) => category.skills)
        .map((s) => ({ id: s.id, name: s.name }));
      setAllSkills(items);
      setSelectedSkills(items);
    }
  }, [data]);

  const allSelected = allSkills.length === selectedSkills.length;

  const skillsNames = useMemo(() => {
    return selectedSkills
      .slice(0, phoneOnly ? 2 : 6)
      .map((s) => s.name)
      .join(', ');
  }, [selectedSkills]);

  const Length = phoneOnly
    ? selectedSkills.length - 2
    : selectedSkills.length - 6;

  const handleTaxTypeCheck = (checked, type: 'w2' | '1099') => {
    if (checked) {
      setSelectedTaxTypes([...selectedTaxTypes, type]);
    } else {
      setSelectedTaxTypes(selectedTaxTypes.filter((t) => t !== type));
    }
  };

  const getErrorMsg = () => {
    if (allSkills.length > 0 && selectedSkills.length === 0) {
      return 'Atleast one skill needs to be selected';
    } else if (selectedTaxTypes.length === 0) {
      return 'Atleast one tax type needs to be selected';
    } else if (zipCode && !isValidUSZip(zipCode)) {
      return 'Not a valid zip code';
    } else if (zipCode && !miles) {
      return 'Miles is required for zip code';
    } else if (!zipCode && miles) {
      return 'Zip code is required with miles.';
    } else if (message && message.length > 500) {
      return 'Announcement length should be btween 1-500 charachters';
    } else if (miles !== undefined) {
      if (miles < 2) {
        return 'The distance has to be 2mi at least';
      } else if (miles > 100) {
        return 'The given miles are exceeding the maximum distance limit of 100mi';
      }
    }
    if (!title) {
      return 'Title is required.';
    }
    if (parse(expTime, 'HH:mm', parseISO(expDate)) <= new Date()) {
      return 'Expiry time should be in future';
    }
  };

  const handleSend = async () => {
    const errorMsg = getErrorMsg();
    if (errorMsg) {
      setError(errorMsg);
      return;
    }
    setError('');
    setLoading(true);
    const payload: any = {
      message,
      filters: {
        skills: selectedSkills.map((s) => Number(s.id)),
        withInMiles: miles || undefined,
        zipCode: zipCode || undefined,
        taxTypes: selectedTaxTypes,
      },
    };

    payload.title = title;
    payload.expireAt = parse(expTime, 'HH:mm', parseISO(expDate)).toISOString();

    try {
      const resp = await axiosClient.post(
        V3Endpoints.CREATE_ANNOUNCEMENT,
        payload,
      );

      if (resp?.data?.id) {
        handleBulkSuccess();
      }
    } catch (e: any) {
      if (e?.response?.data?.message) {
        setError(e.response.data.message);
      }
      console.error(e);
    }
    setLoading(false);
  };
  return (
    <Wrapper>
      <div>
        <Section>
          <Stack alignItems={'center'} direction="row" spacing={2}>
            {phoneOnly ? (
              <ArrowBack onClick={handleBack} />
            ) : (
              <IconButton
                style={{
                  backgroundColor: theme.themeDefault,
                  borderRadius: '24px',
                  cursor: 'alias',
                }}
              >
                <Campaign htmlColor="white" />
              </IconButton>
            )}
            <Heading>Bulk Message</Heading>
          </Stack>
        </Section>
        <Divider />
        <Section>
          <Grid container alignItems={'center'} spacing={{ xs: 0, sm: 0.5 }}>
            <Grid item sm={1.5} xs={3.2}>
              <Body size={phoneOnly ? 'md' : 'lg'}>Choose Skills:</Body>
            </Grid>
            <Grid item sm={10} xs={8}>
              <Body size={phoneOnly ? 'md' : 'lg'}>
                <b>{allSelected ? 'All' : skillsNames}</b>
                {Length > 0 && !allSelected ? ` +${Length}` : ''}
              </Body>
            </Grid>
            <Grid item sm={0.5} xs={0.8}>
              <IconButton
                id="show-skills-btn"
                style={{ paddingLeft: phoneOnly ? '0px' : undefined }}
                onClick={handleClick}
              >
                <KeyboardArrowDown htmlColor={IconColor} />
              </IconButton>
            </Grid>
          </Grid>
        </Section>
        <Divider />
        <Section>
          <Stack alignItems={'center'} direction={'row'} spacing={2}>
            <Body size={phoneOnly ? 'md' : 'lg'}>Tax Type:</Body>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedTaxTypes.includes('w2')}
                  id="tax-type-w2"
                  sx={checkboxStyles}
                  onChange={(e, checked) => {
                    handleTaxTypeCheck(checked, 'w2');
                  }}
                />
              }
              label="W2"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedTaxTypes.includes('1099')}
                  id="tax-type-1099"
                  sx={checkboxStyles}
                  onChange={(e, checked) => {
                    handleTaxTypeCheck(checked, '1099');
                  }}
                />
              }
              label="1099"
            />
          </Stack>
        </Section>
        <Divider />
        <Section>
          <Stack direction={'row'} spacing={{ xs: 2, sm: 6 }}>
            <div>
              <Body as={'span'} size={phoneOnly ? 'md' : 'lg'}>
                Within:
              </Body>
              <TextField
                id="miles-input"
                inputProps={{ style: { padding: '6px' } }}
                placeholder="Miles"
                size="small"
                sx={{
                  ...InputStyles,
                  width: '75px',
                }}
                type="number"
                variant="outlined"
                onChange={(e) => {
                  const val = e.target.value;
                  if (val) setMiles(Number(e.target.value));
                  else setMiles(undefined);
                }}
              />
            </div>
            <div>
              <Body as={'span'} size={phoneOnly ? 'md' : 'lg'}>
                Miles of:
              </Body>
              <TextField
                id="zipcode-input"
                inputProps={{ style: { padding: '6px' } }}
                placeholder="Zipcode"
                size="small"
                sx={{
                  ...InputStyles,
                  width: '90px',
                }}
                variant="outlined"
                onChange={(e) => setZipCode(e.target.value)}
              />
            </div>
          </Stack>
        </Section>
        {
          <>
            <Divider />
            <Section>
              <Body as={'span'} size={phoneOnly ? 'md' : 'lg'}>
                Title:
              </Body>
              <TextField
                id="title"
                inputProps={{ style: { padding: '6px' }, maxLength: 250 }}
                placeholder="Subject"
                sx={{
                  ...InputStyles,
                  width: '80%',
                }}
                value={title}
                variant="outlined"
                onChange={(e) => setTitle(e.target.value)}
              />
            </Section>
            <Divider />
            <Section>
              <Stack direction={'row'} spacing={{ xs: 1, sm: 4 }}>
                <Stack
                  alignItems={'center'}
                  direction={'row'}
                  style={{ width: '50%' }}
                >
                  <Body
                    css={{ width: phoneOnly ? '155px' : '132px' }}
                    size={phoneOnly ? 'md' : 'lg'}
                  >
                    Expiry Date:
                  </Body>
                  <Input
                    css={{
                      borderColor: 'white',
                      textTransform: 'uppercase',
                      padding: '0px',
                    }}
                    min={new Date().toISOString().split('T')[0]}
                    type="date"
                    value={expDate}
                    onChange={(e) => {
                      if (e.target.value) setExpDate(e.target.value);
                    }}
                  />
                </Stack>
                <Stack
                  alignItems={'center'}
                  direction={'row'}
                  spacing={0.5}
                  style={{ width: '50%' }}
                >
                  <Body
                    css={{ width: phoneOnly ? '145px' : '120px' }}
                    size={phoneOnly ? 'md' : 'lg'}
                  >
                    Expiry Time:
                  </Body>
                  <div style={{ width: '150px' }}>
                    <ShiftTimePicker
                      defaultValue={dayjs('23:59', 'HH:mm')}
                      hideClock={true}
                      inputStyle={{
                        '& fieldset': {
                          borderColor: 'white',
                        },
                      }}
                      label="Enter Time"
                      name="Exptime"
                      timeSteps={{ hours: 1, minutes: 1, seconds: 1 }}
                      onChange={(time) => {
                        setExpTime(time?.format('HH:mm') || '');
                      }}
                    />
                  </div>
                </Stack>
              </Stack>
            </Section>
          </>
        }

        <Popover
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={open}
          slotProps={{
            paper: {
              style: { width: '400px', maxHeight: '70vh' },
            },
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          onClose={handleClose}
        >
          <CheckboxList
            allSelected={allSelected}
            data={allSkills}
            selectedData={selectedSkills}
            setSelectedOptions={setSelectedSkills}
          />
        </Popover>

        {showskillsModal && (
          <SelectSkillsModal
            data={allSkills}
            hideModal={() => setShowSkillsModal(false)}
            selectedData={selectedSkills}
            setSelectedOptions={setSelectedSkills}
            title="Select Skills"
          />
        )}
        {error && <Alert severity="error">{error}</Alert>}
        {loading && <LinearProgress />}
      </div>

      <div>
        <Stack
          alignItems={'end'}
          direction={'row'}
          spacing={1}
          style={{
            marginLeft: '10px',
            marginBottom: phoneOnly ? '15px' : '20px',
          }}
        >
          <TextField
            multiline
            maxRows={phoneOnly ? 4 : 5}
            minRows={phoneOnly ? 2 : 3}
            placeholder="Type a message"
            sx={{
              width: '100%',
              backgroundColor: 'white',
              borderRadius: phoneOnly ? '10px' : '24px',
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  display: 'none',
                },
              },
            }}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <div style={{ marginRight: '7px', marginBottom: '10px' }}>
            <IconButton
              disabled={!message}
              id="send-msg-btn"
              style={{
                backgroundColor: theme.themeDefault,
                borderRadius: '24px',
                opacity: !message ? 0.6 : 1,
              }}
              onClick={handleSend}
            >
              <SendOutlined htmlColor="white" />
            </IconButton>
          </div>
        </Stack>
      </div>
    </Wrapper>
  );
};

export default SendBulkMessage;
