import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useMemo, useRef, useState } from 'react';

import { useOrderState } from '../../../context';
import {
  JobDraftState,
  PublishInEnum,
  selectionOptions,
  Step,
  useJobDraftActions,
  useJobDraftState,
} from '../../context';
import BottomBar from '../BottomBar';
import Layout from '../Layout';

import SkillSelection from './SkillSelection';

import Alert from '@/components/Alert';
import Button from '@/components/Button';
import SingleColumnLayout from '@/components/SingleColumnLayout';
import Stack from '@/components/Stack';
import { GAEvent } from '@/constants/gaevents';
import Form from '@/form';
import useMediaQuery from '@/hooks/useMediaQuery';
import { Prettify } from '@/types';
import { JobTypeEnum, Scalars } from '@/types/graphql';
import useAnalytics from '@/util/analytics';

export type FirstStepFormValues = Pick<
  JobDraftState,
  | 'skillCategory'
  | 'skill'
  | 'address'
  | 'addressInstructions'
  | 'contact'
  | 'contactInstructions'
>;

export type FirstStepFormFieldId = keyof FirstStepFormValues;
export type OnChangeFirstStepForm = (
  fieldContext: any,
  fieldId: Prettify<FirstStepFormFieldId>,
) => Scalars['Void'];

export enum FirstStepFieldIdEnum {
  SKILL_CATEGORY = 'skillCategory',
  SKILL = 'skill',
  ADDRESS = 'address',
  ADDRESS_INSTRUCTIONS = 'address_instructions',
}

type Props = {
  setStep: (step: Step) => Scalars['Void'];
  onClose: () => Scalars['Void'];
};

const SkillStep = ({ setStep, onClose }: Props) => {
  const {
    skillCategory: skillCategoryInContext,
    skill: skillInContext,
    address: addressInContext,
    addressInstructions: addressInstructionsInContext,
    contact: contactInContext,
    contactInstructions: contactInstructionsInContext,
    schedules,
  } = useJobDraftState();
  const { updateSkill, updateSchedules, updatePublishing } =
    useJobDraftActions();
  const { orderType, billing } = useOrderState();

  const { logEvent } = useAnalytics();

  const [startTime, setStartTime] = useState<number>(0);

  useEffect(() => {
    setStartTime(new Date().getTime());
  }, []);

  const [formValues, setFormValues] = useState<FirstStepFormValues>({
    skillCategory: skillCategoryInContext,
    skill: skillInContext,
    address: addressInContext,
    addressInstructions: addressInstructionsInContext,
    contact: contactInContext,
    contactInstructions: contactInstructionsInContext,
  });

  const schedulesAlreadyCreated = schedules.length !== 0;
  const continueButtonIsDisabled = useMemo(() => {
    return (
      !formValues.skillCategory ||
      !formValues.skill ||
      !formValues.address ||
      !formValues.contact
    );
  }, [formValues]);

  const handleSubmit = async ({
    skillCategory,
    skill,
    address,
    addressInstructions,
    contact,
    contactInstructions,
  }: FirstStepFormValues) => {
    if (
      schedulesAlreadyCreated &&
      (skillCategoryInContext?.id !== skillCategory?.id ||
        skillInContext?.id !== skill?.id)
    ) {
      // TODO: restart the  <TopBar />
      updateSchedules({ schedules: [] });
    }

    updateSkill({
      skillCategory: skillCategory!,
      skill: skill!,
      address: address!,
      addressInstructions: addressInstructions.trim(),
      contact: contact!,
      contactInstructions: contactInstructions.trim(),
    });
    const isLTA = orderType === JobTypeEnum.LTA;

    logEvent(
      isLTA ? GAEvent.StepOneLta : GAEvent.StepOneGig,
      billing?.account?.id,
      {
        skill_ategory: skillCategory?.id,
        skill: skill?.id,
        address: address?.id,
        contact: contact?.id,
        time_spent: (new Date().getTime() - startTime) / 1000,
      },
    );
    if (isLTA) {
      updatePublishing({
        postSetting: selectionOptions.MY_SELECTIONS,
        publishIn: PublishInEnum.NEVER,
      });
      return setStep(Step.SCHEDULE);
    }

    return setStep(Step.PUBLISHING);
  };

  const onChange: OnChangeFirstStepForm = (fieldContext, fieldId) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [fieldId]: fieldContext.value,
    }));
  };

  const phoneOnly = useMediaQuery('(max-width: 559px)');

  return (
    <Layout>
      <Form initialValues={formValues} onSubmit={handleSubmit}>
        <SingleColumnLayout
          noPadding={phoneOnly}
          size={phoneOnly ? 'full' : 'md'}
        >
          <Stack align="center" justify="center">
            {schedulesAlreadyCreated && (
              <Alert
                description="if you change the selected job category, skill or the address you will have to redo step 2"
                icon={faExclamationTriangle}
                status="warning"
                title="WARNING"
              />
            )}
          </Stack>
          <SkillSelection
            {...{ formValues, onChange, continueButtonIsDisabled }}
          />
          <BottomBar>
            <Button
              a11yLabel="Close job editor"
              appearance="outline"
              label="Cancel"
              type="button"
              onClick={onClose}
            />
            <Button
              a11yLabel="Submit form"
              disabled={continueButtonIsDisabled}
              id="continue-btn"
              label="Continue"
              type="submit"
            />
          </BottomBar>
        </SingleColumnLayout>
      </Form>
    </Layout>
  );
};

export default SkillStep;
