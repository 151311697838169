import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { CloseOutlined } from '@mui/icons-material';
import { Dialog, DialogContent, IconButton } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';

import { TimesheetItem } from '../..';

import AccountDollarIcon from '@/assets/icons/AccountDollarIcon';
import Alert from '@/components/Alert';
import Button from '@/components/Button';
import FormElement from '@/components/FormElement';
import Modal from '@/components/Modal';
import Stack from '@/components/Stack';
import Form from '@/form';
import TextField from '@/form/TextField';
import { GetJobDocument, useSaveTimesheetTipMutation } from '@/graphql';
import useMediaQuery from '@/hooks/useMediaQuery';
import styled from '@/styles';

interface Props {
  hideModal: () => void;
  timesheet?: TimesheetItem;
  jobId: string;
}

const HeaderDialog = styled('span', {
  padding: '10px 20px 15px 25px',
  background: 'linear-gradient(180deg, #EEFFEC 38.16%, #FFF 107.94%)',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const TitleDialog = styled('span', {
  color: '#45A735',
  fontSize: '16px',
  fontWeight: '600',
});

type FormValues = {
  tipAmount: TimesheetItem['tipAmount'];
};

const AddTipModal = ({ hideModal, timesheet, jobId }: Props) => {
  const phoneOnly = useMediaQuery('(max-width: 790px)');

  return phoneOnly ? (
    <Dialog
      PaperProps={{
        style: {
          height: 'fit-content',
          borderRadius: '15px',
        },
      }}
      aria-labelledby="second-options-dialog"
      fullScreen={true}
      open={true}
      sx={{
        '& .MuiDialog-container': {
          alignItems: 'end',
          marginBottom: '2px',
        },
      }}
      onClose={hideModal}
    >
      <HeaderDialog>
        <TitleDialog>Tip Worker</TitleDialog>
        <IconButton aria-label="close" onClick={hideModal}>
          <CloseOutlined />
        </IconButton>
      </HeaderDialog>
      <DialogContent style={{ padding: '25px 25px 55px' }}>
        <TipModalContent
          hideModal={hideModal}
          jobId={jobId}
          timesheet={timesheet}
        />
      </DialogContent>
    </Dialog>
  ) : (
    <Modal
      disableClickout
      size="xxs"
      title={`Tip Worker`}
      wrapperBackground={true}
      onRequestClose={hideModal}
      displayFlex={true}
    >
      <Stack vertical gap={15} style={{ padding: 20 }}>
        <TipModalContent
          hideModal={hideModal}
          jobId={jobId}
          timesheet={timesheet}
        />
      </Stack>
    </Modal>
  );
};

const TipModalContent = ({ timesheet, jobId, hideModal }: Props) => {
  const initialValues: FormValues = useMemo((): FormValues => {
    return {
      tipAmount: timesheet?.tipAmount ?? 0,
    };
  }, [timesheet]);
  const [formValues, setFormValues] = useState<FormValues>(initialValues);
  const [submitError, setSubmitError] = useState<Error | null>(null);
  const [saveTipAmount] = useSaveTimesheetTipMutation({
    refetchQueries: [{ query: GetJobDocument, variables: { jobId } }],
    onCompleted: hideModal,
    onError: () => {
      setSubmitError(new Error('Failed to save tip amount'));
    },
  });

  const handleFormValuesChange = (fieldContext, fieldId: keyof FormValues) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [fieldId]: fieldContext.value,
    }));
  };

  const handleSubmit = useCallback(
    async ({ tipAmount }: FormValues) => {
      setSubmitError(null);
      const timesheetId = timesheet?.id!;
      await saveTipAmount({
        variables: {
          timesheetId,
          tipAmount: Number(tipAmount),
        },
      });
    },
    [formValues, timesheet?.id],
  );

  return (
    <Form
      data-testid="add-timesheet-tip-form"
      id="add-timesheet-tip-form"
      initialValues={formValues}
      style={{ width: '100%' }}
      onSubmit={handleSubmit}
    >
      {submitError && (
        <Alert
          description={submitError.message}
          icon={faExclamationTriangle}
          status={'warning'}
          style={{ padding: '10px' }}
        />
      )}
      <Stack vertical gap={8}>
        <FormElement
          label="Tip Amount"
          style={{
            fontSize: '13px',
            color: '#000',
          }}
        >
          <Stack css={{ position: 'relative' }}>
            <AccountDollarIcon
              style={{
                position: 'absolute',
                top: '12px',
                left: '6px',
                height: '14px',
                width: '14px',
                zIndex: 9,
              }}
            />
            <TextField
              callback={(fieldContext) =>
                handleFormValuesChange(fieldContext, 'tipAmount')
              }
              displayType="block"
              fieldId="tipAmount"
              data-testid="tipAmount"
              max="1000"
              min="0"
              step=".01"
              style={{ paddingLeft: '30px' }}
              type="number"
              width="100%"
            />
          </Stack>
        </FormElement>
        <Button
          a11yLabel="Submit form"
          css={{ width: '100%', borderRadius: '8px' }}
          label="Save"
          type="submit"
          data-testid="save-tip-button"
        />
      </Stack>
    </Form>
  );
};

export default AddTipModal;
