import { SubmitHelpers } from '@area2k/use-form';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import { useFeatureValue } from '@growthbook/growthbook-react';
import { TimeValidationError } from '@mui/x-date-pickers';
import { PickerChangeHandlerContext } from '@mui/x-date-pickers';
import { addHours, addDays, format, isAfter, parse, parseISO } from 'date-fns';
import dayjs, { Dayjs } from 'dayjs';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  checkTimeRange,
  getDifferenceInMinutes,
  isHoliday,
  parseStringsHours,
} from '../../CreateGig/JobEditor/Steps/ScheduleStep/util';
import { BreakTime, breakOptions } from '../../CreateGig/JobEditor/context';
import { getRangeShiftsFormat } from '../util';

import Button from '@/components/Button';
import Card from '@/components/Card';
import CustomErrorsForm, { ErrorSchema } from '@/components/CustomErrorsForm';
import FormElement from '@/components/FormElement';
import HolidayInfoAlert from '@/components/HolidayInfoAlert';
import ItemSelect from '@/components/ItemSelect';
import Modal from '@/components/Modal';
import ShiftTimePicker from '@/components/ShiftTimePicker';
import Stack from '@/components/Stack';
import { Subheading } from '@/components/Typography';
import { FEATURE_TOGGLE } from '@/constants/featuretoggle';
import {
  DISABLE_BREAK_MINUTES,
  INTERVAL_IN_MINUTES,
} from '@/constants/general';
import Input from '@/elements/Input';
import Text from '@/elements/Text';
import Form from '@/form';
import FormColumns from '@/form/FormColumns';
import { useGetSurgeRatesQuery, useUpdateJobScheduleMutation } from '@/graphql';
import useInterval from '@/hooks/useInterval';
import { AccountRateTypeEnum, GetJobQuery } from '@/types/graphql';
import { formatISODate, getUserTimezoneOffset } from '@/util/datetime';
import { handleMutationFormError } from '@/util/error';
import styled from '@/styles';

export type Props = {
  job: GetJobQuery['job'];
  hideModal: () => void;
  limitOfHours: number;
};

const TODAY = format(new Date(), 'yyyy-MM-dd');

const Separator = styled('div', {
  width: '100%',
  border: '1px solid $colors$neutralLightest',
});

type FormValues = {
  startAt: string;
  endAt: string;
  displayDate: string;
  mandatoryBreakTime: BreakTime;
};

const UpdateShiftModal = ({ job, hideModal, limitOfHours }: Props) => {
  const { startAt, endAt } = getRangeShiftsFormat(job.shifts[0]);
  const startTime = dayjs(startAt, 'HH:mm');
  const endTime = dayjs(endAt, 'HH:mm');
  const initDisplayDate = formatISODate(parseISO(job.firstShiftStartAt));
  const initialValues: FormValues = {
    displayDate: initDisplayDate,
    startAt,
    endAt,
    mandatoryBreakTime:
      breakOptions.find((x) => x.value === job.mandatoryBreakTime) ||
      breakOptions[0],
  };

  const minLimitDate = useMemo(() => {
    return addHours(new Date(), limitOfHours);
  }, [limitOfHours]);
  const removeHolidayRates = useFeatureValue(
    FEATURE_TOGGLE.RemoveHolidayRates,
    false,
  );

  const [formValues, setFormValues] = useState<FormValues>(initialValues);
  const [disabledButton, setDisabledButton] = useState<boolean>(false);
  const [isInvalidStartTime, setInvalidStartTime] = useState<boolean>(false);
  const [disableBreak, setDisableBreak] = useState<boolean>(false);

  const { data } = useGetSurgeRatesQuery({
    variables: { fromDate: formatISODate() },
  });

  const isHolidayDate = useMemo(() => {
    if (
      (removeHolidayRates &&
        job.account.rateType === AccountRateTypeEnum.MARKUP) ||
      !data?.surgeRatesFromDate
    ) {
      return false;
    } else {
      const holidays = data?.surgeRatesFromDate;
      return isHoliday(holidays, parseISO(formValues.displayDate));
    }
  }, [job, data, formValues.displayDate]);

  const startDateIsNotToday = useMemo(() => {
    return formValues.displayDate !== TODAY;
  }, [formValues]);

  const [adminUpdateJobSchedule] = useUpdateJobScheduleMutation({
    update: (cache) => {
      cache.modify({
        id: cache.identify(job),
        fields: { firstShiftStartAt() {}, lastShiftEndAt() {}, shifts() {} },
      });
    },
  });

  useInterval(() => {
    if (
      !formValues.startAt ||
      !formValues.endAt ||
      parseStringsHours(formValues.startAt) <= new Date().getTime()
    ) {
      setInvalidStartTime(true);
    } else {
      setInvalidStartTime(false);
    }
  }, 1000);

  const formsValidations: ErrorSchema[] = [
    {
      type: 'timeRange',
      icon: faClock,
      title: 'This shift is below the minimum length.',
      message: `Job duration should be at least 4 hours.`,
      condition: checkTimeRange(
        formValues.endAt,
        formValues.startAt,
        INTERVAL_IN_MINUTES,
      ),
    },
    {
      type: 'timeRange',
      icon: faClock,
      title: 'Start / End time is empty.',
      message: `Start / End time should be set`,
      condition:
        isInvalidStartTime && (!formValues.endAt || !formValues.startAt),
    },
    {
      type: 'timeRange',
      icon: faClock,
      title: 'Shift time cannot be in the past',
      message: `Please select a future shift time.`,
      condition: isInvalidStartTime && !startDateIsNotToday,
    },
  ];

  const { validBreakOptions, difference } = useMemo(() => {
    const difference = getDifferenceInMinutes(
      formValues.endAt,
      formValues.startAt,
    );
    if (difference > DISABLE_BREAK_MINUTES && difference < 480) {
      return { validBreakOptions: breakOptions.slice(0, 3), difference };
    } else {
      return { validBreakOptions: breakOptions, difference };
    }
  }, [formValues.startAt, formValues.endAt]);

  useEffect(() => {
    if (difference <= DISABLE_BREAK_MINUTES) {
      setDisableBreak(true);
      setFormValues((prev) => ({
        ...prev,
        mandatoryBreakTime: breakOptions[0],
      }));
    } else {
      if (difference < 480 && formValues.mandatoryBreakTime.value > 30) {
        setFormValues((prev) => ({
          ...prev,
          mandatoryBreakTime: breakOptions.find((b) => b.value === 30)!,
        }));
      }
      setDisableBreak(false);
    }
  }, [difference]);

  const handleChangeInput = (
    e: React.ChangeEvent<HTMLInputElement>,
    key: string,
  ) => {
    const { value } = e.target;
    setFormValues((prev) => ({ ...prev, [key]: value }));
  };

  const handleTimeChange = (
    time: Dayjs,
    event: PickerChangeHandlerContext<TimeValidationError>,
    key: string,
  ) => {
    const value = time ? time?.format('HH:mm') : '';
    setFormValues((prev) => ({ ...prev, [key]: value }));
  };

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value) {
      return;
    }
    handleChangeInput(e, 'displayDate');
  };
  const handleBreakTime = (fieldContext: BreakTime, fieldId: string) => {
    setFormValues((prev) => ({ ...prev, [fieldId]: fieldContext }));
  };
  const handleSubmit = useCallback(
    async (values: FormValues, { setFormError }: SubmitHelpers) => {
      try {
        const { startAt, endAt, displayDate, mandatoryBreakTime } = formValues;
        const starDate = parse(startAt, 'HH:mm', parseISO(displayDate));
        let endDate = parse(endAt, 'HH:mm', parseISO(displayDate));
        if (isAfter(starDate, endDate)) {
          endDate = addDays(endDate, 1);
        }
        await adminUpdateJobSchedule({
          variables: {
            jobId: job.id,
            firstShiftStartAt: starDate.toISOString(),
            lastShiftEndAt: endDate.toISOString(),
            timeZoneOffset: getUserTimezoneOffset(),
            mandatoryBreakTime: mandatoryBreakTime.value,
          },
        });

        hideModal();
      } catch (err: unknown) {
        handleMutationFormError(err, {
          setFormError,
          errorMap: {
            all: (gqlError) => ({
              title: gqlError.name,
              message: gqlError.message,
              status: 'danger',
            }),
          },
        });
      }
    },
    [formValues],
  );

  return (
    <Modal
      size="xs"
      title="Change Schedule"
      wrapperBackground={true}
      onRequestClose={hideModal}
    >
      <Card.Section>
        <CustomErrorsForm
          schema={formsValidations}
          setDisabledButton={setDisabledButton}
        />
        <Form initialValues={formValues} onSubmit={handleSubmit}>
          <FormColumns>
            <FormElement label="Start Date">
              <Input
                defaultValue={initDisplayDate}
                min={minLimitDate.toISOString().split('T')[0]}
                type="date"
                value={formValues.displayDate}
                onChange={handleDateChange}
              />
            </FormElement>
          </FormColumns>
          {isHolidayDate && <HolidayInfoAlert rate={isHolidayDate.rate} />}
          <Separator css={{ marginBottom: '0.5em' }} />
          <Subheading>Shifts</Subheading>
          <FormColumns css={{ marginTop: '6px' }}>
            <FormElement label="Start Time" style={{ marginBottom: '8px' }}>
              <ShiftTimePicker
                defaultValue={startTime}
                minutesStep={15}
                name="startAt"
                onChange={handleTimeChange}
              />
            </FormElement>
            <FormElement label="End Time" style={{ marginBottom: '8px' }}>
              <ShiftTimePicker
                defaultValue={endTime}
                minutesStep={15}
                name="endAt"
                onChange={handleTimeChange}
              />
            </FormElement>
          </FormColumns>
          <FormElement label="Break Time">
            <ItemSelect
              disabled={disableBreak}
              id="mandatoryBreakTime"
              itemToKey={(item) => item.value}
              itemToString={(item) => (item ? item.label : '')}
              items={validBreakOptions}
              placeholder="Select Break Time"
              selectedItem={formValues.mandatoryBreakTime}
              onSelectedItemChange={({ selectedItem }) => {
                handleBreakTime(
                  selectedItem || breakOptions[0],
                  'mandatoryBreakTime',
                );
              }}
            />
            {disableBreak && (
              <Text css={{ top: '4px' }}>
                <i>
                  Shifts shorter than 6 hours may not have mandatory break
                  times.
                </i>
              </Text>
            )}
            {!disableBreak && difference < 480 && (
              <Text css={{ top: '4px' }}>
                <i>
                  Shifts shorter than 8 hours cannot have break for more than 30
                  minutes.
                </i>
              </Text>
            )}
          </FormElement>
          <Stack justify="end">
            <Button
              a11yLabel="Submit form"
              css={{ width: '100%', marginTop: '4px' }}
              disabled={disabledButton}
              label="Save"
              type="submit"
            />
          </Stack>
        </Form>
      </Card.Section>
    </Modal>
  );
};

export default UpdateShiftModal;
