import useModal from '@area2k/use-modal';
import {
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  ConversationHeader,
  MessageSeparator,
} from '@chatscope/chat-ui-kit-react';
import { useFeatureValue } from '@growthbook/growthbook-react';
import { Stack } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';

import { AnnouncementQuoteWrapper, ReplyMessage } from '../styles';

import UserInfoModal from './ChatInfoModal';

import Avatar from '@/components/Avatar';
import Modal from '@/components/Modal';
import TextStack from '@/components/TextStack';
import { Heading, Small } from '@/components/Typography';
import { FEATURE_TOGGLE } from '@/constants/featuretoggle';
import { PUBNUB_INITIAL_CHAT_LIMIT } from '@/constants/general';
import Text from '@/elements/Text';
import useAuth from '@/hooks/useAuth';
import { ChatGroupGWUserType, ChatGroupInterface } from '@/util/axios/types';
import { getDateandLabel, getTimeString } from '@/util/date';

type ChatDetailContainerProps = {
  sendMessage: (args: any) => void;
  chatGroup: ChatGroupInterface;
  messages: any[];
  backClick: () => void;
  phoneOnly: boolean;
  fetchMoreMessages: any;
};
type MessageInfoProps = {
  msg: any;
  currentAdmin: any;
  time: string;
};

const ChatDetails = ({
  sendMessage,
  chatGroup,
  messages = [],
  backClick,
  phoneOnly,
  fetchMoreMessages,
}: ChatDetailContainerProps) => {
  const { currentAdmin } = useAuth();
  const [showModal, hideModal] = useModal(() => {
    return <ProfileModal chatGroup={chatGroup} hideModal={hideModal} />;
  }, [chatGroup]);

  const msgListRef = useRef();

  const [loadingMore, setLoadingMore] = useState(false);
  useEffect(() => {
    setLoadingMore(false);
  }, [messages]);

  const onYReachStart = () => {
    if (loadingMore === true) {
      return;
    }

    if (
      (messages && messages.length < PUBNUB_INITIAL_CHAT_LIMIT) ||
      chatGroup.allMessagesLoaded
    ) {
      setLoadingMore(false);
    } else {
      setLoadingMore(true);
      fetchMoreMessages(chatGroup.id);
    }
  };

  const [showUserModal, hideUserModal] = useModal(() => (
    <UserInfoModal hideModal={hideUserModal}>
      <UserInfo chatGroup={chatGroup} phoneOnly={phoneOnly} />
    </UserInfoModal>
  ));
  const isActive = !!chatGroup?.active;

  let dateLabel =
    messages.length > 0
      ? getDateandLabel(new Date(Number(messages[0].timetoken) / 10000))
      : '';

  return (
    <div
      style={{
        maxHeight: phoneOnly ? 'calc(100vh - 140px)' : '91vh',
        overflow: 'auto',
        width: '100%',
      }}
    >
      <ChatContainer>
        <ConversationHeader
          id="chat-detail-header"
          style={{ backgroundColor: '#fff', cursor: 'pointer' }}
        >
          {phoneOnly && (
            <ConversationHeader.Back id="btn-back" onClick={backClick} />
          )}
          <ConversationHeader.Content
            id="chat-detail-header-content"
            onClick={() => {
              if (isActive) {
                phoneOnly ? showUserModal() : showModal();
              }
            }}
          >
            <Stack direction={'row'} spacing={1.5}>
              <Avatar
                firstName={chatGroup?.name}
                size={phoneOnly ? 'sm' : 'm'}
                src={chatGroup?.avatar}
              />
              <Heading>{chatGroup?.name}</Heading>
            </Stack>
          </ConversationHeader.Content>
        </ConversationHeader>
        <MessageList
          ref={msgListRef}
          className="custom-bg-chat"
          loadingMore={loadingMore}
          onYReachStart={onYReachStart}
        >
          {messages &&
            messages.map((msg, index) => {
              const formattedDate = new Date(Number(msg.timetoken) / 10000);
              const label = getDateandLabel(formattedDate);
              const time = getTimeString(formattedDate);
              if (label === dateLabel && index > 0) {
                return (
                  <MessageInfo
                    key={msg.timetoken}
                    currentAdmin={currentAdmin}
                    msg={msg}
                    time={time}
                  />
                );
              } else {
                dateLabel = label;
                return (
                  <React.Fragment key={msg.timetoken}>
                    <MessageSeparator className="custom-chat-line">
                      {dateLabel}
                    </MessageSeparator>
                    <MessageInfo
                      currentAdmin={currentAdmin}
                      msg={msg}
                      time={time}
                    />
                  </React.Fragment>
                );
              }
            })}
        </MessageList>
        {isActive ? (
          <MessageInput
            attachButton={false}
            className="custom-bg-chat custom-chat-input"
            id="chat-input-active"
            placeholder="Type a message"
            onSend={(message, textContent, innerText) => {
              if (msgListRef && msgListRef.current) {
                msgListRef.current.scrollToBottom();
              }
              sendMessage(innerText);
            }}
          />
        ) : (
          <MessageInput
            attachButton={false}
            className="custom-bg-chat-disabled custom-chat-input"
            disabled={true}
            id="chat-input-disabled"
            placeholder="This worker has been disabled"
            sendButton={false}
          />
        )}
      </ChatContainer>
    </div>
  );
};

type UserInfoProps = {
  chatGroup: ChatGroupInterface;
  phoneOnly: boolean;
};

const UserInfo = ({ chatGroup, phoneOnly }: UserInfoProps) => {
  return (
    <div
      style={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: phoneOnly ? '0px' : '30px',
      }}
    >
      <Avatar size="lg" src={chatGroup?.avatar} />
      <Heading>{chatGroup.name}</Heading>
      <Small>{chatGroup.email ?? 'Email not provided'}</Small>
    </div>
  );
};
const ProfileModal = ({ chatGroup, hideModal }: any) => {
  return (
    <Modal disableClickout size={'xxs'} onRequestClose={hideModal}>
      <UserInfo chatGroup={chatGroup} phoneOnly={false} />
    </Modal>
  );
};

const MessageInfo = ({ msg, currentAdmin, time }: MessageInfoProps) => {
  const user = msg.message.sender;
  const isWorker = user?.type === ChatGroupGWUserType.WORKER || !!user?.userId;
  const announcementReplyContent = msg?.message?.content?.reply;
  const showAnnouncementRepliesQuotes =
    announcementReplyContent && msg.message.sender.id !== currentAdmin.user.id;

  return (
    <Message
      model={{
        message: msg.message.content.message,
        sentTime: msg.timetoken,
        sender: msg.message.sender.firstName,
        direction: msg.message.sender.id === currentAdmin!.user.id ? 1 : 0,
        type: 'custom',
      }}
    >
      <Message.CustomContent>
        {!isWorker && (
          <TextStack>
            <strong>Gravy Staff</strong>
            <Text as="h4" color={'inherit'} size={'md'} weight={'bold'}>
              {`(${user?.firstName || ''} ${user?.lastName || ''})`}
            </Text>
          </TextStack>
        )}
        {isWorker && (
          <Text as="h4" color={'inherit'} size={'md'} weight={'bold'}>
            {`${user?.firstName || ''} ${user?.lastName || ''}`}
          </Text>
        )}
        {showAnnouncementRepliesQuotes && (
          <AnnouncementQuoteWrapper>
            <Text as="h4" color={'inherit'} size={'md'} weight={'bold'}>
              {announcementReplyContent?.title}
            </Text>
            <ReplyMessage>{announcementReplyContent?.message}</ReplyMessage>
          </AnnouncementQuoteWrapper>
        )}
        <br />
        <Message.TextContent text={msg.message.content.message} />
        <div style={{ float: 'right', paddingTop: '3px' }}>{time}</div>
      </Message.CustomContent>
    </Message>
  );
};

export default ChatDetails;
